import './header.scss';
import logo from '../../assets/images/micrologx_logo.png';
import profile from '../../assets/images/profile.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { useWindowSize } from 'react-use';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { routes } from '../../routes/allRoutes';
import { AuthCtx } from '../../context/AuthCtxProvider';
import { _UserType } from '../../constants/staticTypes';
import { BROADCAST_MESSAGES } from '../../constants/constants';
import { ADMIN_STAFF, LOGOUT, ORG_ADMIN, ORG_STAFF, SETTINGS, SUPER_ADMIN } from '../../constants/strings';
import { useTranslation } from '../../hooks/useTranslation';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';

const languageTexts = {
    SETTINGS: SETTINGS,
    LOGOUT: LOGOUT,
    SUPER_ADMIN: SUPER_ADMIN,
    ADMIN_STAFF: ADMIN_STAFF,
    ORG_ADMIN: ORG_ADMIN,
    ORG_STAFF: ORG_STAFF,
};

function HeaderComponent() {
    const panelRef = useRef<any>(null);
    const { width: screenWidth } = useWindowSize();
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const { userType, userData } = useContext(AuthCtx);

    const [isSidebarToggled, setIsSidebarToggled] = useState(false);

    const dropdownPanelItems: MenuItem[] = [
        {
            label: translatedText.SETTINGS,
            icon: 'pi pi-cog',
            command: () => {
                panelRef?.current?.hide();
            },
        },
        {
            label: translatedText.LOGOUT,
            icon: 'pi pi-sign-out',
            command: () => {
                panelRef?.current?.hide();
                window.postMessage(BROADCAST_MESSAGES.FORCE_LOGOUT, window.location.origin);
            },
        },
    ];

    const handleToggle = () => {
        setIsSidebarToggled(!isSidebarToggled);
    };

    const getUserType = (userType: _UserType | null) => {
        switch (userType) {
            case 'adminStaff':
                return translatedText.ADMIN_STAFF;
            case 'superAdmin':
                return translatedText.SUPER_ADMIN;
            case 'organizationAdmin':
                return translatedText.ORG_ADMIN;
            case 'organizationStaff':
                return translatedText.ORG_STAFF;
            default:
                return '';
        }
    };

    useEffect(() => {
        if (isSidebarToggled) {
            document.body.classList.add('toggle-sidebar');
        } else {
            document.body.classList.remove('toggle-sidebar');
        }
    }, [isSidebarToggled]);

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <i className="ri-menu-line toggle-sidebar-btn" role="button" onClick={(e) => handleToggle()}></i>
                <a href={routes.dashboard} className="logo d-flex align-items-center">
                    {screenWidth < 580 ? <></> : <img src={logo} alt="" />}
                </a>
            </div>

            <div className="header-nav ms-auto pe-2 d-flex flex-row align-items-center">
                <LanguageSwitcher />
                <Avatar image={profile} imageFallback={profile} shape="circle" />
                <div
                    className="header-dropdown ms-3 d-flex flex-row align-items-center"
                    role="button"
                    onClick={(event: any) => panelRef?.current?.toggle(event)}
                >
                    <div className="profile-info ms-2 me-3 d-flex flex-column">
                        <span>
                            {userData?.firstName} {userData?.lastName}
                        </span>
                        <small className="text-muted">{getUserType(userType)}</small>
                    </div>
                    <i className="pi pi-angle-down" />
                    <OverlayPanel ref={panelRef} className="header-dropdown-panel">
                        <Menu model={dropdownPanelItems} />
                    </OverlayPanel>
                </div>
            </div>
        </header>
    );
}

export default HeaderComponent;
