import { Toast } from 'primereact/toast';
import { useRef, useEffect } from 'react';
import { _ToastMessageVariant } from '../../../constants/staticTypes';
import { JTranslation } from '../../../helpers/jTranslationHelper';

type MlxToastProps = {
    show: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
    variant: string; // Restricting variant to specific values
    dataTestId?: string;
    timeOut?: number | undefined;
};

const MlxToast = ({ show, onClose, message, variant, dataTestId, timeOut = 3000 }: Readonly<MlxToastProps>) => {
    const toast = useRef<Toast>(null);

    const getVariantMessage = (variant: any): string => {
        switch (variant) {
            case 'success':
                return 'Success';
            case 'info':
                return 'Info';
            case 'warn':
                return 'Warn';
            case 'error':
                return 'Error Occurred';
            default:
                return '';
        }
    };

    const mapVariant = (input: string): _ToastMessageVariant => {
        switch (input.toLowerCase()) {
            case 'error':
            case 'danger':
                return 'error';
            case 'success':
                return 'success';
            case 'info':
                return 'info';
            case 'warn':
            case 'warning':
                return 'warn';
            default:
                throw new Error('Invalid variant');
        }
    };

    useEffect(() => {
        if (show) {
            // const id = new Date().getTime().toString()
            toast.current?.show({
                // id,
                severity: mapVariant(variant),
                summary: <JTranslation text={getVariantMessage(variant)} typeCase="capitalize" />,
                detail: <JTranslation text={message} typeCase="capitalize" />,
                life: timeOut,

                // Implementing callbacks
            });
        }
    }, [show, message, variant, timeOut]);

    const handleOnHide = () => {
        onClose(false); // Resetting the show state
    };

    const handleOnRemove = (message: any) => {
        // Additional logic for when a toast is removed
        onClose(false); // Resetting the show state
    };

    const handleOnShow = () => {};

    return (
        <div className="custom-toast-old">
            <Toast ref={toast} onShow={handleOnShow} onHide={handleOnHide} onRemove={handleOnRemove} />
        </div>
    );
};

export default MlxToast;
