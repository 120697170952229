import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './assets/scss/styles.scss';
import { RouterProvider } from 'react-router-dom';
import { browserRouter } from './routes/allRoutes';
import { QueryClient, QueryClientProvider } from 'react-query';
import Amplify from 'aws-amplify';
import { awsConfig } from './config/awsConfig';
import MlxBlockUI from './components/common/MlxBlockUI/MlxBlockUI';
import { JTranslationProvider } from './context/JTranslationProvider';

Amplify.configure(awsConfig);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <JTranslationProvider apiKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ?? ''}>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={browserRouter} fallbackElement={<MlxBlockUI isLoading={true}></MlxBlockUI>} />
        </QueryClientProvider>
    </JTranslationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
