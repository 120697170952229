import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import '../GetStarted.scss';
import { routes } from '../../../routes/allRoutes';
import { _GetStartedWizard, getStartedWizardOTwoFormSchema, _GetStartedWizardTwo } from '../../../helpers/getStartedHelper';
import CopyRight from '../../common/CopyRight';
import { useFormik } from 'formik';
import {
    BACK,
    CITY,
    CONTACT_NAME,
    CONTINUE,
    COUNTRY,
    CREATE_FIRST_AVENUE,
    CREATE_FIRST_AVENUE_MSG,
    EMAIL,
    PRIMARY_PHONE,
    SECONDARY_PHONE,
    STATE,
    STEP_2,
    VENUE_ADDRESS,
    WHAT_FIRST_AVENUE,
    ZIP,
} from '../../../constants/strings';
import { useTranslation } from '../../../hooks/useTranslation';
import { _GoogleAddress } from '../../../constants/staticTypes';
import MlxGoogleMaps from '../../../components/common/MlxGoogleMaps/MlxGoogleMaps';

type Props = {
    getStartedWizardForm: _GetStartedWizard;
    setGetStartedWizardForm: React.Dispatch<React.SetStateAction<_GetStartedWizard>>;
    locationFormData: _GoogleAddress;
    setLocationFormData: React.Dispatch<React.SetStateAction<_GoogleAddress>>;
};

const languageTexts = {
    STEP_2: STEP_2,
    CREATE_FIRST_AVENUE: CREATE_FIRST_AVENUE,
    CREATE_FIRST_AVENUE_MSG: CREATE_FIRST_AVENUE_MSG,
    CONTACT_NAME: CONTACT_NAME,
    EMAIL: EMAIL,
    PRIMARY_PHONE: PRIMARY_PHONE,
    SECONDARY_PHONE: SECONDARY_PHONE,
    VENUE_ADDRESS: VENUE_ADDRESS,
    ZIP: ZIP,
    CITY: CITY,
    STATE: STATE,
    COUNTRY: COUNTRY,
    BACK: BACK,
    CONTINUE: CONTINUE,
    WHAT_FIRST_AVENUE: WHAT_FIRST_AVENUE,
};

function Step2({ getStartedWizardForm, setGetStartedWizardForm, locationFormData, setLocationFormData }: Readonly<Props>) {
    const navigate = useNavigate();
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const getStartedWizardTwo: _GetStartedWizardTwo = {
        tenantName: getStartedWizardForm.tenantName ?? '',
        contactName: getStartedWizardForm.contactName ?? '',
        email: getStartedWizardForm.email ?? '',
        timezone: getStartedWizardForm.timezone ?? '',
        primaryPhone: getStartedWizardForm.primaryPhone ?? '',
        secondaryPhone: getStartedWizardForm.secondaryPhone ?? '',
        address: getStartedWizardForm.address ?? '',
        zip: getStartedWizardForm.zip ?? '',
        city: getStartedWizardForm.city ?? '',
        state: getStartedWizardForm.state ?? '',
        country: getStartedWizardForm.country ?? '',
    };

    const addressCallback = (address: _GoogleAddress) => {
        setLocationFormData(address);

        getStartedWizardFormik.setFieldValue('address', address?.formatted_address ?? '');
        getStartedWizardFormik.setFieldValue('zip', address?.zip_code ?? '');
        getStartedWizardFormik.setFieldValue('city', address?.city_town ?? '');
        getStartedWizardFormik.setFieldValue('state', address?.state ?? '');
        getStartedWizardFormik.setFieldValue('country', address?.country_region ?? '');
    };

    const formSubmit = (data: _GetStartedWizardTwo) => {
        setGetStartedWizardForm({ ...getStartedWizardForm, ...data });
        navigate(routes.onBoardCreateVenue + 'step3');
    };

    const getStartedWizardFormik = useFormik({
        enableReinitialize: true,
        initialValues: getStartedWizardTwo,
        validationSchema: getStartedWizardOTwoFormSchema,
        onSubmit: (data) => formSubmit(data),
    });

    return (
        <>
            <div className="col-md-7 d-none d-md-block d-flex flex-column align-items-center justify-content-center onboard-bg-img"></div>
            <div className="col-md-5 bg-white d-flex flex-column align-items-center justify-content-between shadow">
                <div></div>
                <form className="py-3 w-100 px-4 d-flex flex-column" onSubmit={getStartedWizardFormik.handleSubmit}>
                    <div className="step-container">
                        <span className="step-number">{translatedText.STEP_2}</span>
                        <span className="step-title">{translatedText.CREATE_FIRST_AVENUE}</span>
                        <span className="step-note">{translatedText.CREATE_FIRST_AVENUE_MSG}</span>
                    </div>

                    <div className="step3-container-form">
                        <div className="row">
                            <div className="col-md-12 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="venue-name">
                                    {translatedText.WHAT_FIRST_AVENUE} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik?.touched?.tenantName && getStartedWizardFormik?.errors?.tenantName ? 'border-danger' : ''}`}
                                    autoComplete="off"
                                    value={getStartedWizardFormik.values?.tenantName}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('tenantName', event.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.CONTACT_NAME} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.contactName ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.contactName}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('contactName', event.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.EMAIL} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.email ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.email}
                                    onChange={(event) =>
                                        getStartedWizardFormik.setFieldValue('email', event.target.value?.trim()?.toLowerCase())
                                    }
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.PRIMARY_PHONE} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.primaryPhone ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.primaryPhone}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('primaryPhone', event.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.SECONDARY_PHONE}
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.secondaryPhone ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.secondaryPhone}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('secondaryPhone', event.target.value)}
                                />
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    Time Zone <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.timezone ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.timezone}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('timezone', event.target.value)}
                                />
                            </div>
                        </div> */}

                        <div className="mb-2 d-flex flex-column">
                            <label className="mb-1" htmlFor="username">
                                {translatedText.VENUE_ADDRESS} <span className="text-danger">*</span>
                            </label>
                            <InputText
                                className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.address ? 'border-danger' : ''}`}
                                value={getStartedWizardFormik.values.address}
                                onChange={(event) => getStartedWizardFormik.setFieldValue('address', event.target.value)}
                            />
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <MlxGoogleMaps addressCallback={addressCallback} address={locationFormData} style={{ height: '300px' }} />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.ZIP} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.zip ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.zip}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('zip', event.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.CITY} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.city ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.city}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('city', event.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.STATE} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.state ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.state}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('state', event.target.value)}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    {translatedText.COUNTRY} <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik.errors.country ? 'border-danger' : ''}`}
                                    value={getStartedWizardFormik.values.country}
                                    onChange={(event) => getStartedWizardFormik.setFieldValue('country', event.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="step-container-footer">
                        <MlxButton
                            className="ms-2"
                            label={translatedText.CONTINUE}
                            icon="ri-arrow-right-line"
                            iconPos="right"
                            raised
                            type="submit"
                            disabled={!getStartedWizardFormik.isValid}
                        />
                    </div>
                </form>
                <CopyRight />
            </div>
        </>
    );
}

export default Step2;
