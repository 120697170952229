import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PermissionsCheck from './permissionsCheck';
import { routes } from './allRoutes';
import { AuthCtx } from '../context/AuthCtxProvider';

const AuthRoute = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoading, isSignedIn } = useContext(AuthCtx);

    useEffect(() => {
        const redirectToDashboard = [routes.login, routes.forgotPassword];

        // redirect to login, if not logged
        if (!isSignedIn && !isLoading) {
            return navigate(routes.login);
        }

        // redirect to dashboard, if logged in
        if (!isLoading && isSignedIn && redirectToDashboard.includes(location.pathname)) {
            return navigate(routes.dashboard);
        }
    }, [navigate, isSignedIn, isLoading, location.pathname]);

    return (
        <PermissionsCheck>
            <Outlet />
        </PermissionsCheck>
    );
};

export default AuthRoute;
