import { Auth } from 'aws-amplify';
import { QueryClient } from 'react-query';
import HttpServiceHelper from './httpServiceHelper';
import { _HttpMethods, _UserType } from '../constants/staticTypes';
import { getAdminDataByUserNameApi, getCognitoUserNameApi, getOrgDataByUserNameApi } from '../constants/apiEndPoints';

export type UserLogin = {
    username: string;
    password: string;
};

// user authentication
export const userLogin = (userDetails: UserLogin) => Auth.signIn(userDetails.username?.trim(), userDetails.password);

// user session
export const userSession = () => Auth.currentAuthenticatedUser({ bypassCache: false });

// user custom attributes
export const userCustomAttributes = async (user: any, attribute: string) => {
    const userAttributes = await Auth.userAttributes(user);
    return userAttributes.find((attr: any) => attr.Name === attribute)?.Value ?? null;
};

export const getUserName = (userType: _UserType, username: string) => {
    if (userType === 'superAdmin' || userType === 'adminStaff') {
        return username;
    }
    return `${username}#organization`;
};

// user logout
export const userLogout = async () => {
    await Auth.signOut();
    localStorage.clear();
};

// check cognito data and clear
export const clearCognitoData = () => {
    const cognitoRegex = /CognitoIdentityServiceProvider/;
    const checkIfCognitoDetails = Object.keys(localStorage);
    const checkIfCognitoDetailsPresent = checkIfCognitoDetails?.some((value) => cognitoRegex?.test(value));
    if (checkIfCognitoDetailsPresent) {
        localStorage.clear();
    }
};

// get user info and assigned permissions
export const getUserDetailsByUserName = (queryClient: QueryClient, userType: _UserType, userName: string) => {
    return HttpServiceHelper({
        url: userType === 'superAdmin' || userType === 'adminStaff' ? getAdminDataByUserNameApi : getOrgDataByUserNameApi,
        method: _HttpMethods.POST,
        queryClient,
        data: {
            userName,
        },
    });
};

// get cognito user-name
export const getCognitoUserName = (queryClient: QueryClient, userName: string) => {
    return HttpServiceHelper({
        queryClient,
        url: getCognitoUserNameApi,
        method: _HttpMethods.POST,
        noAuth: true,
        data: {
            userName,
        },
    });
};
