import { Dispatch, SetStateAction } from 'react';
import MlxDialog from '../../../../components/common/MlxDialog/MlxDialog';
import { useFormik } from 'formik';
import { addAdminPermission, AddPermissionFormSchema, initialAddAdminPermissionFormData } from '../../../../helpers/permissionHelper';
import { JTranslation } from '../../../../helpers/jTranslationHelper';
import { InputText } from 'primereact/inputtext';
import { ADD, ADD_PERMISSION, CANCEL, PERMISSION_DESC, PERMISSION_NAME } from '../../../../constants/strings';
import { InputTextarea } from 'primereact/inputtextarea';
import MlxButton from '../../../../components/common/MlxButton/MlxButton';
import { useTranslation } from '../../../../hooks/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import HttpServiceHelper from '../../../../helpers/httpServiceHelper';
import { _PermissionGroup, _ToastMessageProps } from '../../../../constants/staticTypes';
import { AlertVariant } from '../../../../constants/constants';
import { ADMIN_PERMISSION_GROUP_LIST } from '../../../../constants/queryKeys';

type _AddAdminPermissionProps = {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setToastMessage: React.Dispatch<SetStateAction<_ToastMessageProps>>;
    setActiveGroupPermission: React.Dispatch<SetStateAction<_PermissionGroup | null>>;
};

const languageTexts = {
    ADD: ADD,
    CANCEL: CANCEL,
};

function AddAdminPermission({ visible, setVisible, setToastMessage, setActiveGroupPermission }: Readonly<_AddAdminPermissionProps>) {
    const queryClient = useQueryClient();
    const addPermissionMutation = useMutation(HttpServiceHelper);
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const onSuccess = (data: any) => {
        setActiveGroupPermission((data?.data as _PermissionGroup) ?? null);
        setToastMessage({
            message: data?.message ?? '',
            variant: AlertVariant.SUCCESS,
            show: true,
        });
        queryClient.refetchQueries([ADMIN_PERMISSION_GROUP_LIST]);
        resetForm();
    };

    const onError = (error: string, variant: string) => {
        setToastMessage({
            message: error,
            variant,
            show: true,
        });
        resetForm();
    };

    const resetForm = () => {
        addAdminPermissionForm.resetForm();
        setVisible(false);
    };

    const addAdminPermissionForm = useFormik({
        enableReinitialize: true,
        initialValues: initialAddAdminPermissionFormData,
        validationSchema: AddPermissionFormSchema,
        onSubmit: (data) => {
            let payload = data;
            addAdminPermission(addPermissionMutation, payload, onSuccess, onError);
        },
    });

    const header = () => {
        return <JTranslation text={ADD_PERMISSION} type="html" typeCase="pascal" />;
    };

    return (
        <MlxDialog visible={visible} onHide={() => setVisible(false)} header={header}>
            <form onSubmit={addAdminPermissionForm.handleSubmit}>
                <div className="mb-3 d-flex flex-column">
                    <label className="mb-1" htmlFor="permissionRoleName">
                        <JTranslation text={PERMISSION_NAME} typeCase="pascal" /> <span className="text-danger">*</span>
                    </label>
                    <InputText
                        className={`custom-input-text p-inputtext-sm ${addAdminPermissionForm?.touched?.permissionRoleName && addAdminPermissionForm?.errors?.permissionRoleName ? 'border-danger' : ''}`}
                        id="permissionRoleName"
                        value={addAdminPermissionForm?.values?.permissionRoleName}
                        onChange={(event) => {
                            addAdminPermissionForm.setFieldTouched('permissionRoleName', true);
                            addAdminPermissionForm.setFieldValue('permissionRoleName', event.target.value, true);
                        }}
                    />
                </div>
                <div className="mb-3 d-flex flex-column">
                    <label className="mb-1" htmlFor="permissionRoleDescription">
                        <JTranslation text={PERMISSION_DESC} typeCase="pascal" />
                    </label>
                    <InputTextarea
                        rows={3}
                        className={`${addAdminPermissionForm?.touched?.permissionRoleDescription && addAdminPermissionForm?.errors?.permissionRoleDescription ? 'border-danger' : ''}`}
                        id="permissionRoleDescription"
                        value={addAdminPermissionForm?.values?.permissionRoleDescription}
                        onChange={(event) => {
                            addAdminPermissionForm.setFieldTouched('permissionRoleDescription', true);
                            addAdminPermissionForm.setFieldValue('permissionRoleDescription', event.target.value, true);
                        }}
                    />
                </div>
            </form>
            <div className="mt-4 d-flex justify-content-end">
                <MlxButton
                    label={translatedText.CANCEL}
                    onClick={() => {
                        addAdminPermissionForm.resetForm();
                        setVisible(false);
                    }}
                    icon="ri-close-large-line"
                    iconPos="right"
                    size="small"
                    outlined
                    type="button"
                />
                <MlxButton
                    type="button"
                    className="ms-2"
                    label={translatedText.ADD}
                    icon={'ri-add-line'}
                    iconPos="right"
                    size="small"
                    onClick={() => addAdminPermissionForm.submitForm()}
                    loading={addPermissionMutation?.isLoading}
                    disabled={addPermissionMutation?.isLoading}
                />
            </div>
        </MlxDialog>
    );
}

export default AddAdminPermission;
