import { FileUpload, FileUploadProps, FileUploadSelectEvent } from 'primereact/fileupload';
import './MlxImageUpload.scss';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import logger from '../../../utils/logger';
import { getNewImage, uploadFile } from '../../../helpers/fileUploadHelper';
import { AlertVariant, FileType } from '../../../constants/constants';
import { useMutation } from 'react-query';
import HttpServiceHelper from '../../../helpers/httpServiceHelper';
import { _S3ImageResponse, _ToastMessageProps } from '../../../constants/staticTypes';
import { useRef, useState } from 'react';
import MlxToast from '../MlxToast/MlxToast';
import { defaultToastData } from '../../../helpers/commonHelper';
import { UPLOAD_PHOTO_ERROR, USER_UPLOAD_PHOTO_ERROR } from '../../../constants/strings';
import { replaceMessageValues } from '../../../utils/common';
import { Dialog } from 'primereact/dialog';
import MlxCropImage from './MlxCropImage';
import heic2any from 'heic2any';

type MlxImageUploadProps = FileUploadProps & {
    image: _S3ImageResponse;
    setImage: React.Dispatch<React.SetStateAction<_S3ImageResponse>>;
    cropImage?: boolean;
    validateImageDimension?: { width: number; height: number; aspectRatio: number };
};

const MlxImageUpload = (props: Readonly<MlxImageUploadProps>) => {
    let { accept, chooseOptions, onSelect, image, setImage, validateImageDimension, cropImage = false, ...rest } = props;

    const cropModalRef = useRef(null);
    const uploadFileMutation = useMutation(HttpServiceHelper);
    const fileUploadRef = useRef(null);

    const [toastMessage, setToastMessage] = useState<_ToastMessageProps>(defaultToastData);
    const [cropImageUrl, setCropImageUrl] = useState('');
    const [showCropModal, setShowCropModal] = useState(false);

    const uploadSuccess = (imageKey: string, imageUrl: string) => {
        clearImage();
        setImage({ imageKey, imageUrl });
    };

    const uploadError = (errorResponse: FileType) => {
        logger.error('Error on uploading image', errorResponse);

        removeUploadedImage();
        setToastMessage({ message: USER_UPLOAD_PHOTO_ERROR, variant: AlertVariant.ERROR, show: true });
    };

    const uploadImage = (file: File) => {
        // Upload to S3
        setShowCropModal(false);
        uploadFile(FileType.IMAGE, file, uploadFileMutation, uploadSuccess, uploadError, setToastMessage);
    };

    const customOnSelect = (event: FileUploadSelectEvent) => {
        let file: File = event.files[0];
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = async (fileReaderEvent) => {
            // convert other image type to JPEG. support for HEIC, HEIF images.
            const fileName = file.name ?? '';
            const fileExtension = fileName.split('.').pop()?.toLowerCase() ?? '';
            if (fileExtension === 'heic' || fileExtension === 'heif') {
                const blob = await heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                });
                const updatedBlob = Array.isArray(blob) ? blob[0] : blob;
                file = new File([updatedBlob], file.name.replace(/\.[^/.]+$/, '.jpeg'), { type: 'image/jpeg' });
            }

            const img = getNewImage();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const { naturalWidth: width, naturalHeight: height } = img;

                // Image validation
                if (
                    validateImageDimension?.width &&
                    validateImageDimension?.height &&
                    (width < validateImageDimension.width || height < validateImageDimension.height)
                ) {
                    setToastMessage({
                        message: replaceMessageValues(UPLOAD_PHOTO_ERROR, [validateImageDimension?.width, validateImageDimension?.height]),
                        variant: AlertVariant.ERROR,
                        show: true,
                    });
                    removeUploadedImage();
                    return;
                }

                if (cropImage) {
                    setCropImageUrl(img.src);
                    setShowCropModal(true);
                } else {
                    // Upload to S3
                    uploadImage(file);
                }
            };
        };
        reader.readAsDataURL(file);
    };

    const clearImage = () => {
        setCropImageUrl('');
        (fileUploadRef?.current as any)?.clear();
    };

    const removeUploadedImage = () => {
        clearImage();
        setImage({ imageKey: null, imageUrl: null });

        // TODO: Remove from s3 too, once an api developed.
    };

    return (
        <>
            {/* Toast messages */}
            <MlxToast
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(defaultToastData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            {/* Crop modal */}
            <Dialog
                ref={cropModalRef}
                header="Crop Image"
                visible={showCropModal}
                style={{ visibility: 'hidden' }}
                breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                onHide={() => {
                    clearImage();
                    setShowCropModal(false);
                }}
                onShow={() => {
                    // Needed, since the crop dimension's object fit is not set to cover on first render/load
                    const cropModalContainer = (cropModalRef?.current as any)?.getElement() as HTMLDivElement;
                    if (cropModalContainer?.style) {
                        cropModalContainer.style.width = '50vw';
                        cropModalContainer.style.height = '75%';

                        setTimeout(() => {
                            cropModalContainer.style.visibility = 'visible';
                        }, 100);
                    }
                }}
            >
                <MlxCropImage image={cropImageUrl} cropDimension={validateImageDimension} uploadImage={uploadImage} />
            </Dialog>

            <div className="image-layout rounded">
                {!image?.imageUrl && (
                    <FileUpload
                        ref={fileUploadRef}
                        chooseOptions={
                            chooseOptions ?? {
                                label: ' ',
                                icon: uploadFileMutation?.isLoading ? 'pi pi-spin pi-spinner' : 'ri-add-large-fill',
                            }
                        }
                        accept={accept ?? 'image/*,.heic,.heif'}
                        onSelect={onSelect ?? customOnSelect}
                        {...rest}
                    />
                )}

                {image?.imageUrl && (
                    <>
                        <Image src={image?.imageUrl} width="160" preview />
                        <Button className="remove-image-btn" icon="pi pi-times" rounded text raised onClick={removeUploadedImage} />
                    </>
                )}
            </div>
        </>
    );
};

export default MlxImageUpload;
