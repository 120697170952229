import withSidebar from '../../../hoc/withSideBar/withSideBar';
import './Permission.scss';
import { TabMenu } from 'primereact/tabmenu';
import { useState } from 'react';
import { _ToastMessageProps } from '../../../constants/staticTypes';
import { defaultToastData } from '../../../helpers/commonHelper';
import MlxToast from '../../../components/common/MlxToast/MlxToast';
import AdminPermission from '../admin-permission/AdminPermission';
import OrgPermission from '../admin-org-permission/AdminOrgPermission';

const items = [{ label: 'Default Permissions' }, { label: 'Org Permissions' }];

function Permission() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [toastMessage, setToastMessage] = useState<_ToastMessageProps>(defaultToastData);

    return (
        <>
            <MlxToast
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(defaultToastData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />
            <div className="page-section">
                <span className="page-header">Manage Permissions</span>
                <div className="page-content">
                    <div className="content-body">
                        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
                        {activeIndex === 0 && <AdminPermission setToastMessage={setToastMessage} />}
                        {activeIndex === 1 && <OrgPermission setToastMessage={setToastMessage} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default withSidebar(Permission);
