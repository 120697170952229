export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// common
export const getShortCodeToUrlApi = '/public/admin_auth/get_url';
export const checkUserNameAvailability = '/public/admin_auth/check_user_availability';
export const getStartedApi = '/public/admin_auth/get_started';
export const getCognitoUserNameApi = '/public/admin_auth/fetch_user';
export const fileUploadApi = '/private/files_tma/tenants/s3/image/upload';

// Apis for Admin
export const getAdminDataByUserNameApi = '/private/admin/users/check_username';
export const getOrganizationListApi = '/private/admin/organization/list';
export const addOrganizationApi = '/private/admin/organization/add';
export const updateOrganizationApi = '/private/admin/organization/update';
export const deleteOrganizationApi = '/private/admin/organization/delete';
export const updateOrganizationStatusApi = '/private/admin/organization/status';
export const resendOrganizationInvitationApi = '/private/admin/organization/resend_invitation_email';
export const getAdminPermissionGroupListApi = '/private/admin/permission_group/permission';
export const getAdminPermissionListApi = '/private/admin/permission_group/permission_list';
export const getAdminOrgPermissionGroupListApi = '/private/admin/permission_group/organization_permissions';
export const getAdminOrgPermissionListApi = '/private/admin/permission_group/organization_permission_list';
export const addAdminPermissionApi = '/private/admin/permission_group/permission';
export const deleteAdminPermissionApi = '/private/admin/permission_group/permission';
export const updateAdminPermissionApi = '/private/admin/permission_group/permission';
export const deleteAdminOrgPermissionApi = '/private/admin/permission_group/organization_permissions';
export const updateAdminOrgPermissionApi = '/private/admin/permission_group/organization_permissions';
export const addAdminOrgPermissionApi = '/private/admin/permission_group/organization_permissions';

// Apis for Organization
export const getOrgDataByUserNameApi = '/private/organization/users/check_username';
export const checkDomainAvailabilityApi = '/private/organization/venue/check_domain_availability';
export const getOrgPermissionGroupListApi = '/private/organization/permission_group/permission';
export const getOrgPermissionListApi = '/private/organization/permission_group/permission_list';

// Apis for venue
export const addVenueApi = '/private/organization/venue/add';

// Apis for permission
export const getPermissionListApi = '/private/admin/permission_group/permission_list';
