import { useNavigate } from 'react-router-dom';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import '../GetStarted.scss';
import { routes } from '../../../routes/allRoutes';
import { _GetStartedWizard, getStartedWizardOTwoFormSchema } from '../../../helpers/getStartedHelper';
import CopyRight from '../../common/CopyRight';
import { useEffect } from 'react';
import MlxImageUpload from '../../../components/common/MlxImageUpload/MlxImageUpload';
import {
    BACK,
    CONTINUE,
    CREATE_FIRST_AVENUE,
    CREATE_SECOND_AVENUE_MSG,
    STEP_3,
    UPLOAD_MOBILE_LOGO,
    UPLOAD_WEB_LOGO,
    UPLOAD_DIMENSION_MSG,
} from '../../../constants/strings';
import { useTranslation } from '../../../hooks/useTranslation';
import { _S3ImageResponse } from '../../../constants/staticTypes';
import { IMAGE_TYPE } from '../../../constants/constants';
import MlxToolTip from '../../../components/common/MlxToolTip/MlxToolTip';

type Props = {
    getStartedWizardForm: _GetStartedWizard;
    setGetStartedWizardForm: React.Dispatch<React.SetStateAction<_GetStartedWizard>>;
    webLogo: _S3ImageResponse;
    setWebLogo: React.Dispatch<React.SetStateAction<_S3ImageResponse>>;
    mobileLogo: _S3ImageResponse;
    setMobileLogo: React.Dispatch<React.SetStateAction<_S3ImageResponse>>;
};

const languageTexts = {
    STEP_3: STEP_3,
    CREATE_FIRST_AVENUE: CREATE_FIRST_AVENUE,
    CREATE_SECOND_AVENUE_MSG: CREATE_SECOND_AVENUE_MSG,
    UPLOAD_WEB_LOGO: UPLOAD_WEB_LOGO,
    UPLOAD_MOBILE_LOGO: UPLOAD_MOBILE_LOGO,
    BACK: BACK,
    CONTINUE: CONTINUE,
    UPLOAD_DIMENSION_MSG: UPLOAD_DIMENSION_MSG,
};

function Step3({ getStartedWizardForm, setGetStartedWizardForm, webLogo, setWebLogo, mobileLogo, setMobileLogo }: Readonly<Props>) {
    const navigate = useNavigate();
    const translatedText = useTranslation(languageTexts, 'capitalize');

    useEffect(() => {
        setGetStartedWizardForm({
            ...getStartedWizardForm,
            webLogo: webLogo?.imageKey ?? '',
            mobileLogo: mobileLogo?.imageKey ?? '',
        });
    }, [webLogo, mobileLogo]);

    useEffect(() => {
        getStartedWizardOTwoFormSchema.validate(getStartedWizardForm).catch((error) => {
            navigate(routes.onBoardCreateVenue + 'step2', { replace: true });
        });
    }, [navigate]);

    return (
        <>
            <div className="col-md-7 d-none d-md-block d-flex flex-column align-items-center justify-content-center onboard-bg-img"></div>
            <div className="col-md-5 bg-white d-flex flex-column align-items-center justify-content-between shadow">
                <div></div>
                <div className="w-100 px-4 py-3">
                    <div className="step-container">
                        <span className="step-number">{translatedText.STEP_3}</span>
                        <span className="step-title">{translatedText.CREATE_FIRST_AVENUE}</span>
                        <span className="step-note">{translatedText.CREATE_SECOND_AVENUE_MSG}</span>
                    </div>
                    <div className="step4-container-form custom-logo-upload">
                        <div className="d-flex flex-column me-3">
                            <div className="d-flex align-items-center">
                                <span className="mb-1">{translatedText.UPLOAD_WEB_LOGO}</span>
                                <MlxToolTip target={'.uploadWebLogoInfo'}>
                                    <i
                                        className={`pi pi-info-circle mb-1 ms-2 text-primary uploadWebLogoInfo`}
                                        data-pr-tooltip={`${translatedText.UPLOAD_DIMENSION_MSG}: ${IMAGE_TYPE['WEB_LOGO'].width}x${IMAGE_TYPE['WEB_LOGO'].height}`}
                                        data-pr-position="left"
                                    ></i>
                                </MlxToolTip>
                            </div>
                            <MlxImageUpload
                                mode="basic"
                                image={webLogo}
                                setImage={setWebLogo}
                                validateImageDimension={IMAGE_TYPE['WEB_LOGO']}
                                cropImage={true}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="mb-1">{translatedText.UPLOAD_MOBILE_LOGO}</span>
                                <MlxToolTip target={'.uploadMobileLogoInfo'}>
                                    <i
                                        className={`pi pi-info-circle mb-1 ms-2 text-primary uploadMobileLogoInfo`}
                                        data-pr-tooltip={`${translatedText.UPLOAD_DIMENSION_MSG}: ${IMAGE_TYPE['MOBILE_LOGO'].width}x${IMAGE_TYPE['MOBILE_LOGO'].height}`}
                                        data-pr-position="left"
                                    ></i>
                                </MlxToolTip>
                            </div>
                            <MlxImageUpload
                                mode="basic"
                                image={mobileLogo}
                                setImage={setMobileLogo}
                                validateImageDimension={IMAGE_TYPE['MOBILE_LOGO']}
                                cropImage={true}
                            />
                        </div>
                    </div>
                    <div className="step-container-footer">
                        <MlxButton
                            label={translatedText.BACK}
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(routes.onBoardCreateVenue + 'step2');
                            }}
                            icon="ri-arrow-left-line"
                            iconPos="left"
                            outlined
                            raised
                            disabled={false}
                        />
                        <MlxButton
                            className="ms-2"
                            label={translatedText.CONTINUE}
                            icon="ri-arrow-right-line"
                            iconPos="right"
                            raised
                            type="button"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(routes.onBoardCreateVenue + 'step4');
                            }}
                        />
                    </div>
                </div>
                <CopyRight />
            </div>
        </>
    );
}

export default Step3;
