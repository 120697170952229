import { DataTableStateEvent } from 'primereact/datatable';
import { QueryClient } from 'react-query';

export enum _HttpMethods {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete',
}

export type _UserType = 'superAdmin' | 'adminStaff' | 'organizationAdmin' | 'organizationStaff';

export type _User = {
    activeAdmins: [];
    createdAt: string;
    email: string;
    firstName: string;
    hireDate: string;
    id: string;
    isActive: boolean;
    lastName: string;
    loginUserName: string;
    passwordLessLoginOnly: boolean;
    phoneNumber: string;
    photoKey: string;
    photoThumbnail: string;
    preferredLangKey: string;
    preferredName: string;
    preferredUserName: string;
    sendPasswordCreation: false;
    signedUrl: false;
    staffPermissionRoles: [];
    updatedAt: string;
    userId: string;
    venueCount: number;
};

export enum _InvitationStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}

export type _Organization = {
    address: string;
    city: string;
    contactName: string;
    country: string;
    emailId: string;
    id: string;
    invitationStatus: _InvitationStatus;
    isActive: boolean;
    organizationName: string;
    primaryPhone: string;
    secondaryPhone: string;
    state: string;
    zip: string;
    website: string;
    userName: string;
    registrationDate: string;
};

export type _Pagination = {
    currentPage: number;
    from: number;
    lastPage: number;
    limit: number;
    nextPageUrl: string;
    path: string;
    prevPageUrl: string | null;
    to: number;
    total: number;
};

export type _DatatablePageEvent = DataTableStateEvent & {
    rowsPerPageOptions: number[];
    forceUpdate: number;
    paginatorTemplate: string;
    search: string;
    totalRows: number;
};

export type _Payload = {
    queryClient?: QueryClient;
    url: string;
    method?: _HttpMethods;
    noAuth?: boolean;
    headers?: {};
    data?: {};
    isFile?: boolean;
    isSignedUrl?: boolean;
};

export type _ErrorMessage = {
    data: {
        message: string;
        errors: [
            {
                key: string;
                message: string;
                param: string;
            },
        ];
    };
};

export type _ToastMessageProps = {
    message: string;
    variant: string;
    show: boolean;
};

export type _ToastMessageVariant = 'success' | 'info' | 'warn' | 'error' | undefined;

export type _TypeCase = 'upper' | 'lower' | 'capitalize' | 'pascal' | 'none';

export type _Country = {
    name: string;
    code: string;
    langKey: string;
    langName: string;
};

export type _GoogleAddress = {
    city_town: string | undefined;
    country_region: string | undefined;
    state: string | undefined;
    zip_code: string | undefined;
    lat: number | undefined;
    lng: number | undefined;
    place_id: string | undefined;
    formatted_address: string | undefined;
};

export type _S3ImageResponse = {
    imageUrl: string | null;
    imageKey: string | null;
};

export type _CropType =
    | 'LANDSCAPE'
    | 'PORTRAIT'
    | 'BANNER'
    | 'MENU'
    | 'BEVERAGE'
    | 'FOOD'
    | 'INGREDIENT'
    | 'NEWS'
    | 'EVENTS'
    | 'MOBILE_LOGO'
    | 'WEB_LOGO';

export type _ImageType = {
    [key in _CropType]: {
        width: number;
        height: number;
        aspectRatio: number;
    };
};

export type _Permission = {
    id: string;
    parentPermissionId: string;
    permissionKey: string;
    permissionName: string;
    defaultValue: boolean;
    permissionDescription: string;
    isActive: boolean;
    isDefault: boolean;
    createdAt: string;
    updatedAt: string;
    parent_permission_id: string;
    subPermissions: _Permission[];
};

export type _SubGroupPermission = {
    id: string;
    isActive: boolean;
    isDefault: boolean;
    parentPermissionKey: string;
    permissionKey: string;
    permissionName: string;
    defaultPermissionId: string;
    permissionId?: string;
};

export type _GroupPermission = {
    id?: string;
    defaultPermissionId: string;
    permissionName: string;
    permissionKey: string;
    parentPermissionKey: string;
    isActive: boolean;
    isDefault: boolean;
    subPermissions: _SubGroupPermission[];
};

export type _PermissionGroup = {
    id: string;
    permissionRoleName: string;
    permissionRoleDescription: string;
    isActive: boolean;
    isDefault: boolean;
    createdAt: string;
    updatedAt: string;
    permissions: _GroupPermission[];
};
