import { APP_NAME, APP_YEAR } from '../../constants/constants';
import { FOOTER_BRAND } from '../../constants/strings';
import { useTranslation } from '../../hooks/useTranslation';

const languageTexts = {
    FOOTER_BRAND: FOOTER_BRAND,
};

function CopyRight() {
    const translatedText = useTranslation(languageTexts, 'capitalize');

    return (
        <small className="mb-2 d-flex flex-row justify-content-center align-items-center">
            <i className="ri-copyright-line"></i>
            <span className="ms-1">
                {APP_YEAR} {APP_NAME}, {translatedText.FOOTER_BRAND}
            </span>
        </small>
    );
}

export default CopyRight;
