import { useContext, useEffect } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { CommonCtx } from '../context/CommonCtxProvider';

type _CustomQueryOptions = UseQueryOptions & {
    enableLoader?: boolean;
};

export function useQueryHook({ enableLoader, ...options }: _CustomQueryOptions) {
    const { setShowLoader } = useContext(CommonCtx);
    const customQuery = useQuery(options);

    useEffect(() => {
        if (customQuery?.isFetching || customQuery?.isLoading) {
            if (enableLoader) {
                setShowLoader(true);
            }
        } else {
            setShowLoader(false);
        }
    }, [customQuery, enableLoader, setShowLoader]);

    return customQuery;
}
