import { QueryClient, UseMutationResult } from 'react-query';
import HttpServiceHelper from './httpServiceHelper';
import {
    addOrganizationApi,
    getOrganizationListApi,
    resendOrganizationInvitationApi,
    updateOrganizationApi,
} from '../constants/apiEndPoints';
import { _HttpMethods, _Organization, _Payload } from '../constants/staticTypes';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../utils/common';
import { AlertVariant } from '../constants/constants';

export type _OrganizationForm = {
    organizationName: string;
    isActive: boolean;
    contactName: string;
    email: string;
    primaryPhone: string;
    secondaryPhone: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    website: string;
    userName?: string;
    id?: string;
};

export const initialOrganizationFormData: _OrganizationForm = {
    organizationName: '',
    isActive: true,
    contactName: '',
    email: '',
    primaryPhone: '',
    secondaryPhone: '',
    address: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    website: '',
    userName: '',
    id: '',
};

export const convertOrganizationData = (organizationData: _Organization | undefined) => {
    if (!organizationData) return null;

    return {
        organizationName: organizationData.organizationName ?? '',
        isActive: organizationData.isActive ?? false,
        contactName: organizationData.contactName ?? '',
        email: organizationData.emailId ?? '',
        primaryPhone: organizationData.primaryPhone ?? '',
        secondaryPhone: organizationData.secondaryPhone ?? '',
        address: organizationData.address ?? '',
        city: organizationData.city ?? '',
        zip: organizationData.zip ?? '',
        state: organizationData.state ?? '',
        country: organizationData.country ?? '',
        website: organizationData.website ?? '',
        userName: organizationData.userName ?? '',
        id: organizationData.id ?? '',
    } as _OrganizationForm;
};

export const OrganizationFormSchema = Yup.object().shape({
    organizationName: Yup.string().required('Organization name is required'),
    contactName: Yup.string().required('Contact name is required'),
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    primaryPhone: Yup.string().required('Primary phone is required'),
    website: Yup.string()
        .lowercase()
        .matches(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/, 'Invalid website URL')
        .test('is-valid-domain', 'Please enter a valid website', (value) => {
            if (!value) return true; // Allow empty values if not required
            try {
                // Additional domain validation checks
                const url = new URL(value.startsWith('http') ? value : `https://${value}`);

                // Reject localhost and IP addresses
                if (url.hostname === 'localhost') return false;

                // Ensure domain has at least one dot and valid TLD
                const parts = url.hostname.split('.');
                return parts.length >= 2 && parts[parts.length - 1].length >= 2 && parts[parts.length - 1].length <= 63;
            } catch {
                return false;
            }
        }),
});

export const getOrganizationList = (queryClient: QueryClient, page: number, limit: number, search: string) => {
    let url = `${getOrganizationListApi}?limit=${limit}&page=${page}`;
    if (search) {
        url += search;
    }

    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const addOrganization = (
    addOrganizationMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _OrganizationForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    addOrganizationMutation.mutate(
        {
            url: addOrganizationApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const updateOrganization = (
    updateOrganizationMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _OrganizationForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    updateOrganizationMutation.mutate(
        {
            url: updateOrganizationApi,
            method: _HttpMethods.PUT,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const resendInvitation = (
    organizationMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: { organizationId: string },
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    organizationMutation.mutate(
        {
            url: resendOrganizationInvitationApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};
