import { QueryClient, UseMutationResult } from 'react-query';
import { _GroupPermission, _HttpMethods, _Payload, _Permission, _PermissionGroup, _SubGroupPermission } from '../constants/staticTypes';
import {
    addAdminOrgPermissionApi,
    addAdminPermissionApi,
    deleteAdminOrgPermissionApi,
    deleteAdminPermissionApi,
    getAdminOrgPermissionGroupListApi,
    getAdminOrgPermissionListApi,
    getAdminPermissionGroupListApi,
    getAdminPermissionListApi,
    getOrgPermissionGroupListApi,
    getOrgPermissionListApi,
    getPermissionListApi,
    updateAdminOrgPermissionApi,
    updateAdminPermissionApi,
} from '../constants/apiEndPoints';
import HttpServiceHelper from './httpServiceHelper';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../utils/common';
import { AlertVariant } from '../constants/constants';
import { cloneDeep } from 'lodash';

export type _RolePermissions = {
    defaultPermissionId: string;
    isActive: boolean;
    isEnabled: boolean;
};

export type _UpdatePermissionForm = {
    id: string;
    permissionRoleName: string;
    permissionRoleDescription: string;
    rolePermissions: _RolePermissions[];
};

export type _AddPermissionForm = {
    permissionRoleName: string;
    permissionRoleDescription: string;
};

export const initialAddAdminPermissionFormData: _AddPermissionForm = {
    permissionRoleName: '',
    permissionRoleDescription: '',
};

export const AddPermissionFormSchema = Yup.object().shape({
    permissionRoleName: Yup.string().required('Permission name is required'),
    permissionRoleDescription: Yup.string(),
});

export const getAdminPermissionWithGroupList = async (queryClient: QueryClient) => {
    const [adminPermissionListResponse, adminPermissionGroupListResponse] = await Promise.all([
        getAdminPermissionList(queryClient),
        getAdminPermissionGroupList(queryClient),
    ]);
    return {
        adminPermissionListData: adminPermissionListResponse?.data?.data as _Permission[],
        adminPermissionGroupListData: adminPermissionGroupListResponse?.data?.data?.lists as _PermissionGroup[],
    };
};

export const getAdminOrgPermissionWithGroupList = async (queryClient: QueryClient) => {
    const [adminOrgPermissionListResponse, adminOrgPermissionGroupListResponse] = await Promise.all([
        getAdminOrgPermissionList(queryClient),
        getAdminOrgPermissionGroupList(queryClient),
    ]);
    return {
        adminOrgPermissionListData: adminOrgPermissionListResponse?.data?.data as _Permission[],
        adminOrgPermissionGroupListData: adminOrgPermissionGroupListResponse?.data?.data?.lists as _PermissionGroup[],
    };
};

export const getPermissionList = (queryClient: QueryClient) => {
    return HttpServiceHelper({
        url: getPermissionListApi,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getAdminPermissionList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getAdminPermissionListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getAdminPermissionGroupList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getAdminPermissionGroupListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getAdminOrgPermissionList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getAdminOrgPermissionListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getAdminOrgPermissionGroupList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getAdminOrgPermissionGroupListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getOrgPermissionList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getOrgPermissionListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const getOrgPermissionGroupList = (queryClient: QueryClient, limit = 500) => {
    const url = `${getOrgPermissionGroupListApi}?limit=${limit}`;
    return HttpServiceHelper({
        url,
        method: _HttpMethods.GET,
        queryClient,
    });
};

export const addAdminPermission = (
    addAdminPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _AddPermissionForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    addAdminPermissionMutation.mutate(
        {
            url: addAdminPermissionApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const addAdminOrgPermission = (
    addAdminOrgPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _AddPermissionForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    addAdminOrgPermissionMutation.mutate(
        {
            url: addAdminOrgPermissionApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const deleteAdminPermission = (
    deleteAdminPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: { id: string },
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    deleteAdminPermissionMutation.mutate(
        {
            url: deleteAdminPermissionApi,
            method: _HttpMethods.DELETE,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const deleteAdminOrgPermission = (
    deleteAdminOrgPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: { id: string },
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    deleteAdminOrgPermissionMutation.mutate(
        {
            url: deleteAdminOrgPermissionApi,
            method: _HttpMethods.DELETE,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const updateAdminPermission = (
    updateAdminOrgPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _UpdatePermissionForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    updateAdminOrgPermissionMutation.mutate(
        {
            url: updateAdminOrgPermissionApi,
            method: _HttpMethods.PUT,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const updateAdminOrgPermission = (
    updateAdminPermissionMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _UpdatePermissionForm,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    updateAdminPermissionMutation.mutate(
        {
            url: updateAdminOrgPermissionApi,
            method: _HttpMethods.PUT,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};

export const updateFullPermissions = (fullPermissions: _Permission[], latestDetails: _GroupPermission[]): _Permission[] => {
    // reset all permission to  false
    const resetPermissionsList = (activePermissions: _Permission[]): _Permission[] => {
        return activePermissions.map((permission) => {
            return {
                ...permission,
                isActive: false,
                subPermissions: resetPermissionsList(permission.subPermissions),
            };
        });
    };
    const resetPermissions = resetPermissionsList(cloneDeep(fullPermissions));

    const userPermissionsMap = new Map();
    cloneDeep(latestDetails).forEach((up) => userPermissionsMap.set(up.defaultPermissionId, up));

    // update full permissions to latest
    const updatePermission = (permission: _Permission) => {
        const userPermission = userPermissionsMap.get(permission.id);
        if (userPermission) {
            permission.isActive = userPermission.isActive;
            if (permission.subPermissions && userPermission.subPermissions) {
                const userSubPermissionsMap = new Map();
                userPermission.subPermissions.forEach((permission: _SubGroupPermission) =>
                    userSubPermissionsMap.set(permission.defaultPermissionId, permission)
                );
                permission.subPermissions.forEach((subPermission) => {
                    const userSubPermission = userSubPermissionsMap.get(subPermission.id);
                    if (userSubPermission) {
                        subPermission.isActive = userSubPermission.isActive;
                    }
                });
            }
        }
        return permission;
    };

    return resetPermissions.map(updatePermission);
};

export const updateOrgFullPermissions = (fullPermissions: _Permission[], latestDetails: _GroupPermission[]): _Permission[] => {
    // reset all permission to  false
    const resetPermissionsList = (activePermissions: _Permission[]): _Permission[] => {
        return activePermissions.map((permission) => {
            return {
                ...permission,
                isActive: false,
                subPermissions: resetPermissionsList(permission.subPermissions),
            };
        });
    };
    const resetPermissions = resetPermissionsList(cloneDeep(fullPermissions));

    const userPermissionsMap = new Map();
    cloneDeep(latestDetails).forEach((up) => userPermissionsMap.set(up.id, up));

    // update full permissions to latest
    const updatePermission = (permission: _Permission) => {
        const userPermission = userPermissionsMap.get(permission.id);
        if (userPermission) {
            permission.isActive = userPermission.isActive;
            if (permission.subPermissions && userPermission.subPermissions) {
                const userSubPermissionsMap = new Map();
                userPermission.subPermissions.forEach((permission: _SubGroupPermission) =>
                    userSubPermissionsMap.set(permission.permissionId, permission)
                );
                permission.subPermissions.forEach((subPermission) => {
                    const userSubPermission = userSubPermissionsMap.get(subPermission.id);
                    if (userSubPermission) {
                        subPermission.isActive = userSubPermission.isActive;
                    }
                });
            }
        }
        return permission;
    };

    return resetPermissions.map(updatePermission);
};

/**
 * Sets `isActive` recursively for a permission and all its children.
 * @param permission - The _Permission object to update.
 * @param isActiveValue - The value to set for `isActive`.
 * @returns Updated _Permission object.
 */
export const setActiveRecursively = (permission: _Permission, isActiveValue: boolean): _Permission => {
    permission.isActive = isActiveValue;

    if (permission.subPermissions && permission.subPermissions.length > 0) {
        permission.subPermissions = permission.subPermissions.map((sub) => setActiveRecursively(sub, isActiveValue));
    }

    return permission;
};

/**
 * Recursively updates the permissions based on the given updated permission and `isActive` value.
 * @param data - Array of _Permission objects to be updated.
 * @param updatedPermission - The _Permission object that contains the updated information.
 * @param isActiveValue - Boolean value to set the `isActive` field of the updated permission.
 * @returns Updated array of _Permission objects.
 */
export const onPermissionChange = (currentPermission: _Permission, activePermissions: _Permission[], isActive: boolean): _Permission[] => {
    return activePermissions.map((permission) => {
        // Check if the current permission is the updated permission
        if (permission.id === currentPermission.id) {
            permission.isActive = isActive;

            // If the updatedPermission is a parent, update all its children
            if (permission.subPermissions && permission.subPermissions.length > 0) {
                permission.subPermissions = permission.subPermissions.map((child) => {
                    child.isActive = isActive;
                    return child;
                });
            }
        }

        // Recursively update subPermissions
        if (permission.subPermissions && permission.subPermissions.length > 0) {
            permission.subPermissions = onPermissionChange(currentPermission, permission.subPermissions, isActive);

            // Determine parent isActive based on children
            const allChildrenInactive = permission.subPermissions.every((sub) => !sub.isActive);

            if (allChildrenInactive) {
                permission.isActive = false;
            } else {
                const hasActiveChild = permission.subPermissions.some((sub) => sub.isActive);
                permission.isActive = permission.isActive || hasActiveChild;
            }
        }

        return permission;
    });
};

/**
 * Converts the _Permission array to _RolePermissions array.
 * @param permissions - The array of _Permission objects.
 * @returns Array of _RolePermissions.
 */
export const convertToRolePermissions = (permissions: _Permission[]): _RolePermissions[] => {
    const result: _RolePermissions[] = [];

    function traverse(permissionArray: _Permission[]) {
        for (const permission of permissionArray) {
            // Only include permissions with isActive: true
            if (permission.isActive) {
                result.push({
                    defaultPermissionId: permission.id,
                    isActive: permission.isActive,
                    isEnabled: true,
                });

                // Recursively process subPermissions
                if (permission.subPermissions && permission.subPermissions.length > 0) {
                    traverse(permission.subPermissions);
                }
            }
        }
    }

    traverse(permissions);
    return result;
};
