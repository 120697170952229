import { CSSProperties } from 'react';
import './MlxActionIcon.scss';

type MlxActionProps = {
    actionType: 'EDIT' | 'DELETE' | 'ADD' | 'CANCEL' | 'INFO' | 'WARNING' | 'SUCCESS' | 'DANGER';
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const MlxActionIcon = (props: Readonly<MlxActionProps>) => {
    let { className, actionType, style, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-action-icon';

    let customStyle: CSSProperties = style ? { ...style } : {};
    switch (actionType) {
        case 'EDIT':
            className += ' ri-pencil-line';
            customStyle = { ...customStyle, color: 'var(--success-color)' };
            break;
        case 'DELETE':
            className += ' ri-delete-bin-5-line';
            customStyle = { ...customStyle, color: 'var(--danger-color)' };
            break;
        case 'ADD':
            className += ' ri-add-large-line';
            customStyle = { ...customStyle, color: 'var(--primary-color)' };
            break;
    }

    return <i className={className} style={customStyle} {...rest}></i>;
};

export default MlxActionIcon;
