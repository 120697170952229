import { Dispatch, SetStateAction, PropsWithChildren, useState, useMemo, createContext } from 'react';
import MlxBlockUI from '../components/common/MlxBlockUI/MlxBlockUI';

interface CommonContextValue {
    showSideBar: boolean;
    setShowSideBar: Dispatch<SetStateAction<boolean>>;
    showLoader: boolean;
    setShowLoader: Dispatch<SetStateAction<boolean>>;
}

export const CommonCtx = createContext<CommonContextValue>({
    showSideBar: false,
    setShowSideBar: () => {},
    showLoader: false,
    setShowLoader: () => {},
});

export const CommonCtxProvider = ({ children }: PropsWithChildren) => {
    const [showSideBar, setShowSideBar] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    return (
        <CommonCtx.Provider
            value={useMemo(() => {
                return {
                    showSideBar,
                    setShowSideBar,
                    showLoader,
                    setShowLoader,
                };
            }, [showSideBar, showLoader])}
        >
            <MlxBlockUI isLoading={showLoader} opacity={0.4}>
                {children}
            </MlxBlockUI>
        </CommonCtx.Provider>
    );
};
