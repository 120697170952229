import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import tableData from '../../../../mocks/organizarion-staff-list.json';
import { useState } from 'react';
import MlxActionIcon from '../../../../components/common/MlxActionIcon/MlxActionIcon';
import { toTypeCase } from '../../../../utils/common';
import MlxInputSwitch from '../../../../components/common/MlxInputSwitch/MlxInputSwitch';

function OrganizationList() {
    const [selectedUsers, setSelectedUsers] = useState<any>(null);

    const statusTemplateWithAction = (rowData: any) => {
        return (
            <div className="d-flex flex-row align-items-center justify-content-between" style={{ maxWidth: '110px', minWidth: '110px' }}>
                <span className="me-2">{toTypeCase(rowData.status, 'capitalize')}</span>
                <MlxInputSwitch checked={false} onChange={() => {}} />
            </div>
        );
    };

    const actionTemplate = (rowData: any) => {
        return (
            <div className="d-flex flex-row align-items-center justify-content-end" style={{ minWidth: '70px' }}>
                <MlxActionIcon className="me-3" actionType="EDIT" />
                <MlxActionIcon className="me-3" actionType="DELETE" />
            </div>
        );
    };

    return (
        <DataTable
            className="custom-p-table"
            value={tableData}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 25, 35, 50]}
            size="small"
            removableSort
            scrollable
            scrollHeight="flex"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
            selectionMode={'checkbox'}
            selection={selectedUsers}
            onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey="id"
        >
            <Column selectionMode="multiple" style={{ maxWidth: '4rem', minWidth: '4rem' }}></Column>
            <Column
                style={{ maxWidth: '12rem', minWidth: '12rem' }}
                sortable
                header="Name"
                body={(data) => `${data.first_name} ${data.last_name}`}
            ></Column>
            <Column style={{ maxWidth: '12rem', minWidth: '12rem' }} field="email" header="Email"></Column>
            <Column style={{ maxWidth: '12rem', minWidth: '12rem' }} field="mobile" header="Phone"></Column>
            <Column style={{ maxWidth: '12rem', minWidth: '12rem' }} header="Venue" body={(rowData) => rowData.venue.name}></Column>
            <Column style={{ minWidth: '110px' }} header="Status" body={statusTemplateWithAction}></Column>
            <Column header="" style={{ minWidth: '70px' }} body={actionTemplate}></Column>
        </DataTable>
    );
}

export default OrganizationList;
