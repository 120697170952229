import { AlertVariant } from '../constants/constants';
import { getErrorMessage } from '../utils/common';
import * as Yup from 'yup';
import logger from '../utils/logger';
import HttpServiceHelper from './httpServiceHelper';
import { _HttpMethods, _Payload } from '../constants/staticTypes';
import { addVenueApi, checkDomainAvailabilityApi } from '../constants/apiEndPoints';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';

export type _GetStartedWizardFour = {
    domainName: string;
};

export type _GetStartedWizardTwo = {
    tenantName: string;
    contactName: string;
    email: string;
    timezone: string;
    primaryPhone: string;
    secondaryPhone: string;
    address: string;
    zip: string;
    city: string;
    state: string;
    country: string;
};

export type _GetStartedWizardThree = {
    webLogo: string;
    mobileLogo: string;
};

export type _GetStartedWizard = _GetStartedWizardFour & _GetStartedWizardTwo & _GetStartedWizardThree;

export const GetStartedWizardFormInitialValues: _GetStartedWizard = {
    tenantName: '',
    domainName: '',
    contactName: '',
    email: '',
    timezone: '',
    primaryPhone: '',
    secondaryPhone: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    webLogo: '',
    mobileLogo: '',
};

export const domainRef = { current: null as string | null, isProgress: false, isAvailable: false };

export const getStartedWizardOneFormSchema = Yup.object().shape({
    domainName: Yup.string()
        .required('Domain is required')
        .test('is-unique', 'This domain is already taken', (value) => {
            const prevUsername = domainRef.current;
            domainRef.current = value;

            if (value === prevUsername) {
                if (domainRef.isAvailable) {
                    return true;
                }
                return; // Re-check username only if input value changes
            }

            return new Promise((resolve, reject) => {
                domainRef.isAvailable = false;
                domainRef.isProgress = true;
                checkDomainName(value)
                    ?.then(() => {
                        domainRef.isAvailable = true;
                        resolve(true);
                    })
                    ?.catch((error) => {
                        logger.info('Failed to retrieve data in check-username', error);
                        domainRef.isAvailable = false;
                        resolve(false);
                    })
                    ?.finally(() => {
                        domainRef.isProgress = false;
                    });
            });
        }),
});

const checkDomainName = (domainName: string | undefined) => {
    if (!domainName) return;

    return HttpServiceHelper({
        url: checkDomainAvailabilityApi,
        method: _HttpMethods.POST,
        data: {
            domainName,
        },
    });
};

export const getStartedWizardOTwoFormSchema = Yup.object().shape({
    tenantName: Yup.string().required('Name is required'),
    contactName: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Enter a valid email'),
    primaryPhone: Yup.string().required('Primary phone is required'),
    address: Yup.string().required('Address is required'),
    zip: Yup.string().required('Zip is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
});

export const addVenue = (
    addOrganizationMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _GetStartedWizard,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    addOrganizationMutation.mutate(
        {
            url: addVenueApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};
