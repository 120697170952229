import { Dropdown, DropdownProps } from 'primereact/dropdown';
import './MlxDropDown.scss';

type MlxDropDownProps = DropdownProps & {
    dropDownSize?: 'small' | 'large';
};

const MlxDropDown = (props: Readonly<MlxDropDownProps>) => {
    let { className, dropDownSize, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className = 'mlx-drop-down ' + className;

    if (dropDownSize) {
        className += ` ${dropDownSize}-dropdown`;
    }

    return <Dropdown className={className} {...rest} />;
};

export default MlxDropDown;
