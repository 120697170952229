import { InputText } from 'primereact/inputtext';
import MlxButton from '../../components/common/MlxButton/MlxButton';
import logo from '../../assets/images/micrologx_logo.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from '../../routes/allRoutes';
import {
    ADMIN_ID_HEADER,
    AlertVariant,
    APP_NAME,
    APP_YEAR,
    LANGUAGE_CHANGE_TIME,
    ORG_ID_HEADER,
    TARGET_LANGUAGE,
} from '../../constants/constants';
import './RegisterAccount.scss';
import { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    checkPasswordCriteria,
    getStartedOrganization,
    initialRegisterAccountFormData,
    RegisterAccountSchema,
    usernameRef,
} from '../../helpers/registerAccountHelper';
import logger from '../../utils/logger';
import { useDebounce } from 'primereact/hooks';
import { Button } from 'primereact/button';
import { QueryClient, useMutation } from 'react-query';
import HttpServiceHelper from '../../helpers/httpServiceHelper';
import MlxToast from '../../components/common/MlxToast/MlxToast';
import { _ToastMessageProps, _User, _UserType } from '../../constants/staticTypes';
import { defaultToastData } from '../../helpers/commonHelper';
import { useTranslation } from '../../hooks/useTranslation';
import {
    USERNAME,
    PASSWORD,
    FOOTER_BRAND,
    FIRST_NAME,
    LAST_NAME,
    CONFIRM_PASSWORD,
    USERNAME_TAKEN_MSG,
    GET_STARTED,
    INACTIVE_USER_ERROR,
    PASSWORD_CHECK_LOWERCASE,
    PASSWORD_CHECK_UPPERCASE,
    PASSWORD_CHECK_NUMBER,
    PASSWORD_CHECK_MIN,
    PASSWORD_CHECK_SPECIAL,
} from '../../constants/strings';
import MlxToolTip from '../../components/common/MlxToolTip/MlxToolTip';
import { clearCognitoData, getCognitoUserName, getUserDetailsByUserName, userCustomAttributes, userLogin } from '../../helpers/authHelper';
import { AuthCtx } from '../../context/AuthCtxProvider';

const languageTexts = {
    FIRST_NAME: FIRST_NAME,
    LAST_NAME: LAST_NAME,
    USERNAME: USERNAME,
    PASSWORD: PASSWORD,
    CONFIRM_PASSWORD: CONFIRM_PASSWORD,
    USERNAME_TAKEN_MSG: USERNAME_TAKEN_MSG,
    GET_STARTED: GET_STARTED,
    FOOTER_BRAND: FOOTER_BRAND,
    PASSWORD_CHECK_LOWERCASE: PASSWORD_CHECK_LOWERCASE,
    PASSWORD_CHECK_UPPERCASE: PASSWORD_CHECK_UPPERCASE,
    PASSWORD_CHECK_NUMBER: PASSWORD_CHECK_NUMBER,
    PASSWORD_CHECK_MIN: PASSWORD_CHECK_MIN,
    PASSWORD_CHECK_SPECIAL: PASSWORD_CHECK_SPECIAL,
};

const RegisterAccount = () => {
    const queryClient = new QueryClient();
    const organizationMutation = useMutation(HttpServiceHelper);
    const navigate = useNavigate();
    const translatedText = useTranslation(languageTexts, 'capitalize');
    const [searchParams] = useSearchParams();
    const { setIsSignedIn, setUserType, setUserData } = useContext(AuthCtx);

    // get url params
    const email = searchParams.get('email') ?? '';
    const organizationId = searchParams.get('organizationId') ?? '';

    const [toastMessage, setToastMessage] = useState<_ToastMessageProps>(defaultToastData);
    const [userName, debouncedUserName, setUserName] = useDebounce('', 1000);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const handleLogin = () => {
        // prefetch cognito user-name
        let cognitoUsername = registerAccountForm?.values?.loginUserName;
        getCognitoUserName(queryClient, userName)
            .then((response) => {
                cognitoUsername = response?.data?.data?.preferredUserName ?? userName;
            })
            .catch((error) => {
                logger.info('Error on fetching user-name', error);
            })
            .finally(() => {
                loginMutation.mutate({ username: cognitoUsername, password: registerAccountForm?.values?.password });
            });
    };

    const onLoginSuccess = async (response: any) => {
        // update admin id/org id
        const userType = (await userCustomAttributes(response, 'custom:userType')) as _UserType;
        const adminId = await userCustomAttributes(response, 'custom:userIdDb');
        const orgId = await userCustomAttributes(response, 'custom:organizationId');
        localStorage.setItem(ADMIN_ID_HEADER, adminId ?? '');
        localStorage.setItem(ORG_ID_HEADER, orgId ?? '');

        // user details
        const userName = response?.username ?? '';
        const userDataResponse = await getUserDetailsByUserName(queryClient, userType, userName);
        const userData = userDataResponse.data.data as _User;

        // Update language
        localStorage.setItem(TARGET_LANGUAGE, userData?.preferredLangKey ?? 'en');
        localStorage.setItem(LANGUAGE_CHANGE_TIME, new Date().getTime().toString());

        if (!userData?.isActive) {
            return Promise.reject(new Error(`EXCEPTION: ${INACTIVE_USER_ERROR}`));
        }

        // TODO: update permissions

        setIsSignedIn(true);
        setUserType(userType);
        setUserData(userData);

        // If org admin and don't have any venue, redirect to venue/tenant onboarding
        if (userType === 'organizationAdmin') {
            if (userData?.venueCount <= 0) {
                navigate(routes.onBoardCreateVenue + 'step2', { replace: true });
                return;
            }
        }

        // TODO: handle route path by url
        navigate(routes.dashboard, { replace: true });
    };

    const onLoginError = (error: Error) => {
        clearCognitoData();
        navigate(routes.login, { replace: true });
    };

    const loginMutation = useMutation(userLogin, {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
    });

    const onSuccess = (data: any) => {
        setToastMessage({
            message: data?.message ?? '',
            variant: AlertVariant.SUCCESS,
            show: true,
        });

        // Background login
        handleLogin();
    };

    const onError = (error: string, variant: string) => {
        setToastMessage({
            message: error,
            variant,
            show: true,
        });
    };

    const registerAccountForm = useFormik({
        enableReinitialize: true,
        initialValues: { ...initialRegisterAccountFormData, email, organizationId },
        validationSchema: RegisterAccountSchema,
        onSubmit: (data) => {
            logger.info('payload', data);

            const { confirmPassword, ...payload } = data;
            getStartedOrganization(organizationMutation, payload, onSuccess, onError);
        },
    });

    const passwordCriteria = checkPasswordCriteria(registerAccountForm.values.password);

    const PasswordRequirement = ({ className, text, isValid }: { className: string; text: string; isValid: boolean }) => {
        let styleClass = 'text-secondary';

        if (registerAccountForm?.touched?.password && registerAccountForm?.errors?.password && !isValid) {
            styleClass = 'text-danger';
        }

        if (isValid) {
            styleClass = 'text-success';
        }

        return (
            <div className={`${className} ${styleClass}`}>
                <i className="ri-checkbox-circle-fill"></i>
                <small className="ms-2">{text}</small>
            </div>
        );
    };

    useEffect(() => {
        if (debouncedUserName) {
            registerAccountForm.setFieldTouched('loginUserName', true);
        }
        registerAccountForm.setFieldValue('loginUserName', debouncedUserName, true);
    }, [debouncedUserName]);

    useEffect(() => {
        // redirect to login, if no email or org-id
        if (!email || !organizationId) navigate(routes.login, { replace: true });
    }, [navigate, email, organizationId]);

    return (
        <>
            <MlxToast
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(defaultToastData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />
            <div className="register-page-container row m-0 h-100 w-100">
                <div className="col-md-6 d-none d-md-block d-flex flex-column align-items-center justify-content-center register-page-img"></div>
                <div className="col-md-6 bg-white d-flex flex-column align-items-center justify-content-between shadow">
                    <div></div>
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                        <img className="w-auto" src={logo} alt="logo" />
                        <form
                            onSubmit={registerAccountForm.handleSubmit}
                            className="w-100 mt-2 register-form d-flex flex-column align-items-center"
                            autoComplete="off"
                        >
                            <InputText
                                className={`mt-3 w-100 p-inputtext-sm ${registerAccountForm?.touched?.email && registerAccountForm?.errors?.email ? 'border-danger' : ''}`}
                                autoComplete="off"
                                value={registerAccountForm?.values?.email}
                                disabled={true}
                            />
                            <br />

                            <div className="row">
                                <div className="col-md-6">
                                    <InputText
                                        className={`mt-3 w-100 p-inputtext-sm ${registerAccountForm?.touched?.firstName && registerAccountForm?.errors?.firstName ? 'border-danger' : ''}`}
                                        id="firstName"
                                        value={registerAccountForm?.values?.firstName}
                                        onChange={(event) => {
                                            registerAccountForm.setFieldTouched('firstName', true);
                                            registerAccountForm.setFieldValue('firstName', event.target.value, true);
                                        }}
                                        autoComplete="off"
                                        placeholder={translatedText.FIRST_NAME}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <InputText
                                        className={`mt-3 w-100 p-inputtext-sm ${registerAccountForm?.touched?.lastName && registerAccountForm?.errors?.lastName ? 'border-danger' : ''}`}
                                        id="lastName"
                                        value={registerAccountForm?.values?.lastName}
                                        onChange={(event) => {
                                            registerAccountForm.setFieldTouched('lastName', true);
                                            registerAccountForm.setFieldValue('lastName', event.target.value, true);
                                        }}
                                        autoComplete="off"
                                        placeholder={translatedText.LAST_NAME}
                                    />
                                </div>
                            </div>

                            <div className="mt-3 w-100 input-username">
                                <InputText
                                    className={`w-100 p-inputtext-sm ${registerAccountForm?.touched?.loginUserName && registerAccountForm?.errors?.loginUserName ? 'border-danger' : ''}`}
                                    id="username"
                                    value={userName}
                                    onChange={(event) => setUserName(event.target.value?.trim()?.toLowerCase())}
                                    autoComplete="off"
                                    placeholder={translatedText.USERNAME}
                                />

                                {!usernameRef?.isProgress && !registerAccountForm?.errors?.loginUserName && (
                                    <i className={`pi pi-verified text-success`}></i>
                                )}
                                {!usernameRef?.isProgress &&
                                    registerAccountForm?.errors?.loginUserName &&
                                    registerAccountForm?.touched?.loginUserName && (
                                        <MlxToolTip target={'.usernameErrorMessage'}>
                                            <i
                                                className={`pi pi-times-circle text-danger usernameErrorMessage`}
                                                data-pr-tooltip={translatedText.USERNAME_TAKEN_MSG}
                                                data-pr-position="left"
                                            ></i>
                                        </MlxToolTip>
                                    )}
                                {usernameRef?.isProgress && <i className={`pi pi-spin pi-spinner`}></i>}
                            </div>

                            <div className="w-100 mt-3 position-relative d-flex align-items-center">
                                <InputText
                                    className={`w-100 p-inputtext-sm ${registerAccountForm?.touched?.password && registerAccountForm?.errors?.password ? 'border-danger' : ''}`}
                                    type={showPassword ? 'text' : 'password'}
                                    value={registerAccountForm?.values?.password}
                                    onChange={(event) => {
                                        registerAccountForm.setFieldTouched('password', true);
                                        registerAccountForm.setFieldValue('password', event.target.value, true);
                                    }}
                                    autoComplete="new-password"
                                    placeholder={translatedText.PASSWORD}
                                />
                                <Button
                                    className="position-absolute end-0"
                                    icon={showPassword ? 'ri-eye-off-fill' : 'ri-eye-fill'}
                                    rounded
                                    text
                                    size="large"
                                    severity="secondary"
                                    aria-label="Toggle password visibility"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setShowPassword(!showPassword);
                                    }}
                                    pt={{
                                        root: {
                                            style: {
                                                border: 'none',
                                                background: 'transparent',
                                                boxShadow: 'none',
                                            },
                                        },
                                    }}
                                />
                            </div>

                            <div className="w-100 mt-3 position-relative d-flex align-items-center">
                                <InputText
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className={`w-100 p-inputtext-sm ${registerAccountForm?.touched?.confirmPassword && registerAccountForm?.errors?.confirmPassword ? 'border-danger' : ''}`}
                                    id="confirmPassword"
                                    value={registerAccountForm?.values?.confirmPassword}
                                    onChange={(event) => {
                                        registerAccountForm.setFieldTouched('confirmPassword', true);
                                        registerAccountForm.setFieldValue('confirmPassword', event.target.value, true);
                                    }}
                                    autoComplete="off"
                                    placeholder={translatedText.CONFIRM_PASSWORD}
                                />
                                <Button
                                    className="position-absolute end-0"
                                    icon={showConfirmPassword ? 'ri-eye-off-fill' : 'ri-eye-fill'}
                                    rounded
                                    text
                                    size="large"
                                    severity="secondary"
                                    aria-label="Toggle password visibility"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setShowConfirmPassword(!showConfirmPassword);
                                    }}
                                    pt={{
                                        root: {
                                            style: {
                                                border: 'none',
                                                background: 'transparent',
                                                boxShadow: 'none',
                                            },
                                        },
                                    }}
                                />
                            </div>

                            <div className="w-100 mt-3 position-relative d-flex align-items-center">
                                <div className="row">
                                    <PasswordRequirement
                                        className="col-md-6 d-flex align-items-center"
                                        text={translatedText.PASSWORD_CHECK_LOWERCASE}
                                        isValid={passwordCriteria.lowercase}
                                    />
                                    <PasswordRequirement
                                        className="col-md-6 d-flex align-items-center"
                                        text={translatedText.PASSWORD_CHECK_UPPERCASE}
                                        isValid={passwordCriteria.uppercase}
                                    />
                                    <PasswordRequirement
                                        className="col-md-6 d-flex align-items-center"
                                        text={translatedText.PASSWORD_CHECK_NUMBER}
                                        isValid={passwordCriteria.number}
                                    />
                                    <PasswordRequirement
                                        className="col-md-6 d-flex align-items-center"
                                        text={translatedText.PASSWORD_CHECK_MIN}
                                        isValid={passwordCriteria.length}
                                    />
                                    <PasswordRequirement
                                        className="col-md-12 d-flex align-items-center"
                                        text={translatedText.PASSWORD_CHECK_SPECIAL}
                                        isValid={passwordCriteria.special}
                                    />
                                </div>
                            </div>

                            <MlxButton
                                label={translatedText.GET_STARTED}
                                className="mt-4 w-100"
                                color="primary"
                                raised
                                type="submit"
                                disabled={!registerAccountForm?.isValid}
                                loading={organizationMutation.isLoading || loginMutation.isLoading}
                            />
                        </form>
                    </div>
                    <small className="mb-2 d-flex flex-row justify-content-center align-items-center">
                        <i className="ri-copyright-line"></i>
                        <span className="ms-1">
                            {APP_YEAR} {APP_NAME}, {translatedText.FOOTER_BRAND}
                        </span>
                    </small>
                </div>
            </div>
        </>
    );
};

export default RegisterAccount;
