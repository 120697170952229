import { _ImageType, _UserType } from './staticTypes';

export const APP_NAME = 'MicroLogx';
export const DOMAIN_NAME = 'micrologx.app';
export const APP_YEAR = new Date().getFullYear();
export const TARGET_LANGUAGE = 'targetLanguage';
export const LANGUAGE_CHANGE_TIME = 'languageChangeTime';

export const ADMIN_ID_HEADER = 'X-Admin-Id';
export const ORG_ID_HEADER = 'X-Organization-Id';

export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'MMM D, YYYY';
export const DATE_TIME_FORMAT = 'MMM D, YYYY, h:mm A';

export const MOBILE_BREAKPOINT = 768;

export const BROADCAST_CHANNEL = 'MLX_BROADCAST_CHANNEL';
export const BROADCAST_MESSAGES = {
    FORCE_LOGOUT: 'FORCE_LOGOUT',
};

export const userTypes: { [key: string]: _UserType } = {
    ADMIN_STAFF: 'adminStaff',
    SUPER_ADMIN: 'superAdmin',
    ORG_ADMIN: 'organizationAdmin',
    ORG_ADMIN_STAFF: 'organizationStaff',
};

export enum AlertVariant {
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum FileType {
    IMAGE = 'image',
    VIDEO = 'video',
}

export const IMAGE_TYPE: _ImageType = {
    LANDSCAPE: { width: 600, height: 400, aspectRatio: 3 / 2 },
    PORTRAIT: { width: 600, height: 400, aspectRatio: 3 / 2 },
    BANNER: { width: 900, height: 300, aspectRatio: 3 / 1 },
    MENU: { width: 600, height: 400, aspectRatio: 3 / 2 },
    BEVERAGE: { width: 600, height: 600, aspectRatio: 1 / 1 },
    FOOD: { width: 600, height: 400, aspectRatio: 3 / 2 },
    INGREDIENT: { width: 600, height: 400, aspectRatio: 3 / 2 },
    NEWS: { width: 600, height: 400, aspectRatio: 3 / 2 },
    EVENTS: { width: 600, height: 400, aspectRatio: 3 / 2 },
    MOBILE_LOGO: { width: 500, height: 500, aspectRatio: 2 / 2 },
    WEB_LOGO: { width: 600, height: 400, aspectRatio: 3 / 2 },
};
