import MlxBlockUI from '../../components/common/MlxBlockUI/MlxBlockUI';
import withSidebar from '../../hoc/withSideBar/withSideBar';

function Dashboard() {
    return (
        <div className="page-section">
            <span className="page-header">Dashboard</span>
            <MlxBlockUI>
                <div className="page-content h-100">
                    <div className="content-header">
                        <span className="header-title"></span>
                        <div className="header-fields"></div>
                    </div>
                    <div className="content-body"></div>
                </div>
            </MlxBlockUI>
        </div>
    );
}

export default withSidebar(Dashboard);
