import React from 'react';

const PermissionsCheck = ({ children }: { children: React.ReactNode }) => {
    //TODO: Write the permissions checking logics here
    const isPermission = true;

    if (isPermission) {
        return <>{children}</>;
    } else {
        return <>Fall Back</>; // TODO: render custom fallback UI or fallback component
    }
};

export default PermissionsCheck;
