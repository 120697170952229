const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsCognitoPoolId = process.env.REACT_APP_COGNITO_POOL_ID;
const awsCognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
const awsPinpointAppId = process.env.REACT_APP_PINPOINT_APP_ID;
const awsCognitoIdentityPoolId = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;

export const awsConfig = {
    aws_project_region: awsRegion,
    aws_cognito_region: awsRegion,
    aws_user_pools_id: awsCognitoPoolId,
    aws_user_pools_web_client_id: awsCognitoClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {},
};

export const awsConfigMagicAuth = {
    aws_project_region: awsRegion,
    aws_cognito_region: awsRegion,
    aws_user_pools_id: awsCognitoPoolId,
    aws_user_pools_web_client_id: awsCognitoClientId,
    authenticationFlowType: 'CUSTOM_AUTH',
};

export const awsAmplifyConfig = {
    Auth: {
        identityPoolId: awsCognitoIdentityPoolId,
        region: awsRegion,
    },
};

export const awsAnalyticsConfig = {
    AWSPinpoint: {
        appId: awsPinpointAppId,
        region: awsRegion,
    },
};
