import { Sidebar, SidebarProps } from 'primereact/sidebar';
import { useWindowSize } from 'react-use';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';
import './MlxSideBar.scss';

type MlxSideBarProps = SidebarProps & {};

const MlxSideBar = (props: Readonly<MlxSideBarProps>) => {
    const { width: screenWidth } = useWindowSize();
    let { className, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-sidebar';

    // handle mobile view
    if (screenWidth <= MOBILE_BREAKPOINT) {
        className += ' w-100';
    }

    return <Sidebar className={className} {...rest} />;
};

export default MlxSideBar;
