import { QueryClient } from 'react-query';
import { _HttpMethods } from '../constants/staticTypes';
import { ADMIN_ID_HEADER, BROADCAST_MESSAGES, ORG_ID_HEADER } from '../constants/constants';
import { Auth } from 'aws-amplify';
import { apiBaseUrl } from '../constants/apiEndPoints';
import axios from 'axios';

type Config = {
    [key: string]: string | {};
};

type Payload = {
    queryClient?: QueryClient;
    url: string;
    method?: _HttpMethods;
    noAuth?: boolean;
    headers?: {};
    data?: {};
    isFile?: boolean;
    isSignedUrl?: boolean;
};

const HttpServiceHelper = (payload: Payload) => {
    const fetch = async () => {
        const config: Config = {
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                [ADMIN_ID_HEADER]: localStorage.getItem(ADMIN_ID_HEADER),
                [ORG_ID_HEADER]: localStorage.getItem(ORG_ID_HEADER),
                ...payload.headers,
            },
        };

        if (!payload.noAuth) {
            let token = null;
            await Auth.currentSession()
                .then((data) => {
                    token = data.getIdToken().getJwtToken();
                })
                .catch(() => {
                    token = null;
                });

            if (token !== null) {
                config.headers = {
                    Authorization: `${token}`,
                    ...(config.headers as Config),
                };
            }
        }

        config.method = payload.method ? payload.method : _HttpMethods.GET;
        config.url = payload.isSignedUrl ? payload.url : apiBaseUrl + payload.url; // base url + api
        if (payload.data) {
            config.data = payload.data;
        }

        // Response interceptor
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            async function (error) {
                // check for 401 UnAuthorized error and force logout user
                const { response } = error || {};
                if (response?.status === 401) {
                    window.postMessage(BROADCAST_MESSAGES.FORCE_LOGOUT, window.location.origin);
                }
                return Promise.reject(error);
            }
        );

        return axios(config);
    };
    return fetch();
};

export default HttpServiceHelper;
