import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HttpServiceHelper from '../../helpers/httpServiceHelper';
import { getShortCodeToUrlApi } from '../../constants/apiEndPoints';
import { _HttpMethods } from '../../constants/staticTypes';
import logger from '../../utils/logger';
import { routes } from '../../routes/allRoutes';
import './styles/RedirectPage.scss';

function RedirectPage() {
    const navigate = useNavigate();
    const { shortCode } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await HttpServiceHelper({
                    url: getShortCodeToUrlApi,
                    method: _HttpMethods.POST,
                    noAuth: true,
                    data: {
                        shortCode,
                    },
                });

                const { url } = data?.data ?? {};
                if (url) {
                    logger.info('short code url', url);
                    window.location.replace(url);
                } else {
                    logger.log('Error fetching data:', 'No Url Found');
                    navigate(routes.login, { replace: true });
                }
            } catch (error) {
                logger.error('Error fetching data:', error);
                navigate(routes.login, { replace: true });
            }
        };

        fetchData();
    }, [navigate, shortCode]);

    return (
        <div className="h-100 redirection-container">
            <div className="redirection-wrapper">
                <span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <div className="base">
                    <span></span>
                    <div className="face"></div>
                </div>
            </div>
            <div className="longfazers">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <h1>Redirecting</h1>
        </div>
    );
}

export default RedirectPage;
