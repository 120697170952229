import { Dispatch, SetStateAction, PropsWithChildren, useState, useMemo, createContext, useEffect } from 'react';
import { _User, _UserType } from '../constants/staticTypes';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { clearCognitoData, getUserDetailsByUserName, userCustomAttributes, userLogout, userSession } from '../helpers/authHelper';
import { routes } from '../routes/allRoutes';
import { ADMIN_ID_HEADER, BROADCAST_MESSAGES, LANGUAGE_CHANGE_TIME, ORG_ID_HEADER, TARGET_LANGUAGE } from '../constants/constants';
import { useEffectOnce } from 'react-use';
import MlxBlockUI from '../components/common/MlxBlockUI/MlxBlockUI';

interface AuthContextValue {
    isSignedIn: boolean;
    setIsSignedIn: Dispatch<SetStateAction<boolean>>;
    userType: _UserType | null;
    setUserType: Dispatch<SetStateAction<_UserType | null>>;
    userData: _User | null;
    setUserData: Dispatch<SetStateAction<_User | null>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const AuthCtx = createContext<AuthContextValue>({
    isSignedIn: false,
    setIsSignedIn: () => {},
    userType: null,
    setUserType: () => {},
    userData: null,
    setUserData: () => {},
    isLoading: false,
    setIsLoading: () => {},
});

export const AuthCtxProvider = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userType, setUserType] = useState<_UserType | null>(null);
    const [userData, setUserData] = useState<_User | null>(null);

    // Handle force logout using react query
    const userLogoutMutation = useMutation(userLogout, {
        onMutate: () => {
            setIsLoading(true);
        },
        onSuccess: () => {
            setIsSignedIn(false);
            setUserData(null);
            setUserType(null);
        },
        onSettled: () => {
            navigate(routes.login, {
                replace: true,
            });
            setIsLoading(false);
        },
    });

    // Handle published custom messages
    useEffect(() => {
        const handleMessage = (event: Event) => {
            const messageEvent = event as MessageEvent;

            if (messageEvent?.origin !== window.location.origin) return;

            switch (messageEvent?.data) {
                case BROADCAST_MESSAGES.FORCE_LOGOUT:
                    userLogoutMutation.mutate();
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [userLogoutMutation]);

    useEffectOnce(() => {
        setIsLoading(true);
        // check if already logged
        userSession()
            .then(async (response) => {
                // update admin id/org id
                const userType = (await userCustomAttributes(response, 'custom:userType')) as _UserType;
                const adminId = await userCustomAttributes(response, 'custom:userIdDb');
                const orgId = await userCustomAttributes(response, 'custom:organizationId');
                localStorage.setItem(ADMIN_ID_HEADER, adminId ?? '');
                localStorage.setItem(ORG_ID_HEADER, orgId ?? '');

                // user details
                const userDataResponse = await getUserDetailsByUserName(queryClient, userType, response.username);
                const userData = userDataResponse.data.data as _User;

                // Update language
                localStorage.setItem(TARGET_LANGUAGE, userData?.preferredLangKey ?? 'en');
                localStorage.setItem(LANGUAGE_CHANGE_TIME, new Date().getTime().toString());

                // TODO: update permissions
                // const permissionListResponse = await getPermissionList(queryClient);
                // const parseAdminPermissions = simplifyUsePermissions(userData?.staffPermissionRoles)
                // const parseOrgPermissions = simplifyUsePermissions(userData?.organizationPermissions)

                setIsSignedIn(true);
                setUserType(userType);
                setUserData(userData);

                // If org admin and don't have any venue, redirect to venue/tenant onboarding
                if (userType === 'organizationAdmin') {
                    if (userData?.venueCount <= 0) {
                        navigate(routes.onBoardCreateVenue + 'step2', { replace: true });
                        return;
                    }
                }

                // TODO: handle route path by url
                navigate(routes.dashboard, { replace: true });
            })
            .catch(() => {
                clearCognitoData();
            })
            .finally(() => setIsLoading(false));
    });

    const authContextValue = useMemo(() => {
        return {
            isSignedIn,
            setIsSignedIn,
            userType,
            setUserType,
            userData,
            setUserData,
            isLoading,
            setIsLoading,
        };
    }, [isSignedIn, userType, userData, isLoading]);

    if (isLoading) {
        return <MlxBlockUI isLoading={true}></MlxBlockUI>; // or a loading spinner
    }

    return <AuthCtx.Provider value={authContextValue}>{children}</AuthCtx.Provider>;
};
