export const createImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });
};

// Converts a degree value to its corresponding radian angle.
export function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

// Returns the new bounding area of a rotated rectangle.
export function rotateSize(width: number, height: number, rotation: number) {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
}

// This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
export async function getCroppedImg(
    imageSrc: string,
    pixelCrop: any,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
    cropDimension?: { width: number; height: number; aspectRatio: number }
): Promise<{ file: File; url: string } | null> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement('canvas');

    const croppedCtx = croppedCanvas.getContext('2d');

    if (!croppedCtx) {
        return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(canvas, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

    // Resize the canvas if the dimensions are less than 600x400
    if (cropDimension) {
        const canvas = croppedCanvas;
        let width = croppedCanvas.width;
        let height = croppedCanvas.height;

        const aspectRatio = cropDimension.width / cropDimension.height;
        if (aspectRatio > 1.5) {
            // Landscape mode
            width = 600;
            height = Math.floor(cropDimension.width / aspectRatio);
        } else {
            // Portrait mode
            height = 400;
            width = Math.floor(cropDimension.height * aspectRatio);
        }

        // Create a new canvas with the resized dimensions
        const resizedCanvas = document.createElement('canvas');
        resizedCanvas.width = width;
        resizedCanvas.height = height;
        const ctx = resizedCanvas.getContext('2d');
        if (!ctx) {
            return null;
        }
        ctx.drawImage(canvas, 0, 0, width, height);

        // Update the croppedCanvas with the resized canvas
        croppedCanvas.width = width;
        croppedCanvas.height = height;
        const croppedCtx = croppedCanvas.getContext('2d');
        if (!croppedCtx) {
            return null;
        }
        croppedCtx.drawImage(resizedCanvas, 0, 0);
    }

    // As Base64 string
    // return croppedCanvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
        croppedCanvas.toBlob((file) => {
            if (file) {
                const updatedFile: File = new File([file], 'cropped-image.jpg', { type: 'image/jpeg' });
                resolve({ file: updatedFile, url: URL.createObjectURL(file) });
            } else {
                reject(new Error('Failed to create blob from canvas'));
            }
        }, 'image/jpeg');
    });
}
