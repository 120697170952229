import * as Yup from 'yup';
import HttpServiceHelper from './httpServiceHelper';
import { _HttpMethods, _Payload } from '../constants/staticTypes';
import { checkUserNameAvailability, getStartedApi } from '../constants/apiEndPoints';
import { UseMutationResult } from 'react-query';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../utils/common';
import { AlertVariant } from '../constants/constants';
import logger from '../utils/logger';

export type _RegisterAccountForm = {
    email: string;
    loginUserName: string;
    organizationId: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
};

export type _RegisterAccount = Omit<_RegisterAccountForm, 'confirmPassword'>;

export const initialRegisterAccountFormData: _RegisterAccountForm = {
    email: '',
    loginUserName: '',
    organizationId: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
};

export const usernameRef = { current: null as string | null, isProgress: false, isAvailable: false };

export const checkPasswordCriteria = (password: string) => ({
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /\d/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
});

export const RegisterAccountSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    organizationId: Yup.string().required('OrganizationId is required'),
    password: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/\d/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    loginUserName: Yup.string()
        .required('Username is required')
        .test('is-unique', 'This username is already taken', (value) => {
            const prevUsername = usernameRef.current;
            usernameRef.current = value;

            if (value === prevUsername) {
                if (usernameRef.isAvailable) {
                    return true;
                }
                return; // Re-check username only if input value changes
            }

            return new Promise((resolve, reject) => {
                usernameRef.isProgress = true;
                checkUsername(value)
                    ?.then(() => {
                        usernameRef.isAvailable = true;
                        resolve(true);
                    })
                    ?.catch((error) => {
                        logger.info('Failed to retrieve data in check-username', error);
                        usernameRef.isAvailable = false;
                        resolve(false);
                    })
                    ?.finally(() => {
                        usernameRef.isProgress = false;
                    });
            });
        }),
});

const checkUsername = (userName: string | undefined) => {
    if (!userName) return;

    return HttpServiceHelper({
        url: checkUserNameAvailability,
        method: _HttpMethods.POST,
        noAuth: true,
        data: {
            userName,
        },
    });
};

export const getStartedOrganization = (
    getStartedMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    param: _RegisterAccount,
    onSuccess: (data: any) => void,
    onError: (res: string, variant: string) => void
) => {
    getStartedMutation.mutate(
        {
            url: getStartedApi,
            method: _HttpMethods.POST,
            data: param,
        },
        {
            onSuccess: (res) => onSuccess(res.data),
            onError: (res) => onError(getErrorMessage(res), AlertVariant.ERROR),
        }
    );
};
