import { Tooltip, TooltipProps } from 'primereact/tooltip';
import { ReactNode } from 'react';

type MlxToolTipProps = TooltipProps & {
    children?: ReactNode;
};

const MlxToolTip = (props: Readonly<MlxToolTipProps>) => {
    return (
        <>
            <Tooltip target={props.target} />
            {props.children}
        </>
    );
};

export default MlxToolTip;
