import { _DatatablePageEvent, _GoogleAddress, _Pagination, _ToastMessageProps } from '../constants/staticTypes';

export const defaultPaginationEvent: _DatatablePageEvent = {
    first: 0,
    page: 0,
    sortField: '',
    sortOrder: null,
    filters: {},
    multiSortMeta: [],
    totalPages: 0,
    forceUpdate: 0,
    rowsPerPageOptions: [10, 15, 25, 35, 50],
    rows: 10,
    paginatorTemplate: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
    search: '',
    totalRows: 10,
};

export const defaultPagination: _Pagination = {
    currentPage: 1,
    from: 1,
    lastPage: 2,
    nextPageUrl: '/?page=1&limit=1',
    path: '/',
    limit: 10,
    prevPageUrl: null,
    to: 1,
    total: 10,
};

export const defaultToastData: _ToastMessageProps = {
    message: '',
    variant: '',
    show: false,
};

export const initialAddress: _GoogleAddress = {
    city_town: '',
    country_region: '',
    state: '',
    zip_code: '',
    lat: 0,
    lng: 0,
    place_id: '',
    formatted_address: '',
};
