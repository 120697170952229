import React from 'react';
import { InputText } from 'primereact/inputtext';
import MlxButton from '../../../../components/common/MlxButton/MlxButton';
import MlxSideBar from '../../../../components/common/MlxSideBar/MlxSideBar';
import { InputTextarea } from 'primereact/inputtextarea';

type Props = {
    showSideBar: boolean;
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddRoleSidebar = ({ showSideBar, setShowSideBar }: Readonly<Props>) => {
    return (
        <MlxSideBar
            visible={showSideBar}
            position="right"
            onHide={() => setShowSideBar(false)}
            content={({ hide }) => (
                <>
                    <div className="mlx-sidebar-header">
                        <span className="header-title">Add Role</span>
                        <MlxButton rounded text size="large" icon="ri-close-fill" onClick={(event) => hide(event)} className="text-dark" />
                    </div>

                    <form className="mlx-sidebar-body add-staff-form">
                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="username">
                                Name <span className="text-danger">*</span>
                            </label>
                            <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="username">
                                Area of Operations <span className="text-danger">*</span>
                            </label>
                            <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="username">
                                Description
                            </label>
                            <InputTextarea rows={3} />
                        </div>
                    </form>

                    <div className="mlx-sidebar-footer">
                        <MlxButton
                            label="Cancel"
                            onClick={() => setShowSideBar(false)}
                            icon="ri-close-large-line"
                            iconPos="right"
                            size="small"
                            outlined
                        />
                        <MlxButton
                            className="ms-2"
                            label="Save"
                            onClick={() => {
                                setShowSideBar(false);
                            }}
                            icon="ri-check-line"
                            iconPos="right"
                            size="small"
                        />
                    </div>
                </>
            )}
        />
    );
};

export default AddRoleSidebar;
