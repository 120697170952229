import { useContext, useMemo, useState } from 'react';
import './sidebar.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthCtx } from '../../context/AuthCtxProvider';
import { JTranslation } from '../../helpers/jTranslationHelper';

type SIDE_BAR = {
    title: string;
    slug: string;
    icon: string;
    path: string;
    children?: SIDE_BAR[];
};

const adminSideBarRoutes: SIDE_BAR[] = [
    {
        title: 'Dashboard',
        slug: 'dashboard',
        icon: 'ri-home-3-line',
        path: '/',
    },
    {
        title: 'Organization',
        slug: 'organization',
        icon: 'ri-group-2-line',
        path: '/organization',
        children: [
            {
                title: 'List',
                slug: 'list',
                icon: 'ri-file-list-line',
                path: '/list',
            },
            // {
            //     title: 'Users',
            //     slug: 'users',
            //     icon: 'ri-group-line',
            //     path: '/users',
            // },
            // {
            //     title: 'Roles',
            //     slug: 'roles',
            //     icon: 'ri-user-settings-line',
            //     path: '/roles',
            // },
            // {
            //     title: 'Permissions',
            //     slug: 'permissions',
            //     icon: 'ri-shield-keyhole-line',
            //     path: '/permissions',
            // },
        ],
    },
    {
        title: 'Staff',
        slug: 'staffs',
        icon: 'ri-group-line',
        path: '/staff',
        children: [
            {
                title: 'List',
                slug: 'list',
                icon: 'ri-file-list-line',
                path: '/list',
            },
            {
                title: 'Roles',
                slug: 'roles',
                icon: 'ri-user-settings-line',
                path: '/roles',
            },
            {
                title: 'Permissions',
                slug: 'permissions',
                icon: 'ri-shield-keyhole-line',
                path: '/permissions',
            },
        ],
    },
    {
        title: 'Subscriptions',
        slug: 'subscriptions',
        icon: 'ri-wallet-line',
        path: '/subscriptions',
    },
    {
        title: 'Report',
        slug: 'report',
        icon: 'ri-file-chart-line',
        path: '/report',
    },
    {
        title: 'Help',
        slug: 'help',
        icon: 'ri-question-line',
        path: '/help',
    },
];

const orgSideBarRoutes: SIDE_BAR[] = [
    {
        title: 'Dashboard',
        slug: 'dashboard',
        icon: 'ri-home-3-line',
        path: '/',
    },
    {
        title: 'Venues',
        slug: 'venues',
        icon: 'ri-group-2-line',
        path: '/venues',
        children: [
            {
                title: 'List',
                slug: 'list',
                icon: 'ri-file-list-line',
                path: '/list',
            },
            {
                title: 'Users',
                slug: 'users',
                icon: 'ri-group-line',
                path: '/users',
            },
            {
                title: 'Roles',
                slug: 'roles',
                icon: 'ri-user-settings-line',
                path: '/roles',
            },
            {
                title: 'Permissions',
                slug: 'permissions',
                icon: 'ri-shield-keyhole-line',
                path: '/permissions',
            },
            {
                title: 'F&B Menu',
                slug: 'fbmenu',
                icon: 'ri-file-list-3-line',
                path: '/fbmenu',
            },
            {
                title: 'Schedules',
                slug: 'schedules',
                icon: 'ri-calendar-event-line',
                path: '/schedules',
            },
            {
                title: 'Settings',
                slug: 'settings',
                icon: 'ri-settings-3-line',
                path: '/settings',
            },
        ],
    },
    {
        title: 'Staff',
        slug: 'staffs',
        icon: 'ri-group-line',
        path: '/staff',
        children: [
            {
                title: 'List',
                slug: 'list',
                icon: 'ri-file-list-line',
                path: '/list',
            },
            {
                title: 'Roles',
                slug: 'roles',
                icon: 'ri-user-settings-line',
                path: '/roles',
            },
            {
                title: 'Permissions',
                slug: 'permissions',
                icon: 'ri-shield-keyhole-line',
                path: '/permissions',
            },
        ],
    },
    {
        title: 'Subscriptions',
        slug: 'subscriptions',
        icon: 'ri-wallet-line',
        path: '/subscriptions',
    },
    {
        title: 'Report',
        slug: 'report',
        icon: 'ri-file-chart-line',
        path: '/report',
    },
    {
        title: 'Help',
        slug: 'help',
        icon: 'ri-question-line',
        path: '/help',
    },
];

function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();

    const { userType } = useContext(AuthCtx);
    const sideBarRoutes = useMemo(() => {
        return userType === 'adminStaff' || userType === 'superAdmin' ? adminSideBarRoutes : orgSideBarRoutes;
    }, [userType]);

    const [collapseState, setCollapseState] = useState<string>('');

    const handleMenuCollapse = (menu: string) => {
        setCollapseState((prev) => (prev === menu ? '' : menu));
    };

    return (
        <aside id="sidebar" className="sidebar">
            <ul className="sidebar-nav" id="sidebar-nav">
                {sideBarRoutes.map((menu) => {
                    const isActive =
                        location.pathname === menu.path || menu.children?.some((child) => location.pathname === menu.path + child.path);
                    const isChildActive = menu.children?.some((child) => location.pathname === menu.path + child.path);

                    return (
                        <li key={menu.slug} className="nav-item">
                            <button
                                className={`nav-link w-100 ${collapseState === menu.slug || isChildActive ? '' : 'collapsed'} ${isActive && !isChildActive ? 'active' : ''}`}
                                onClick={() => {
                                    if (menu.children && menu.children.length > 0) {
                                        handleMenuCollapse(menu.slug);
                                    } else {
                                        navigate(menu.path);
                                    }
                                }}
                            >
                                <i className={`${menu.icon}`}></i>
                                <span>
                                    <JTranslation text={menu.title} typeCase="pascal" />
                                </span>
                                {menu.children && menu.children.length > 0 && <i className="ri-arrow-down-s-line ms-auto"></i>}
                            </button>
                            <ul
                                id="components-nav"
                                className={`nav-content collapse ${collapseState === menu.slug || isChildActive ? 'show' : ''}`}
                            >
                                {menu.children?.map((childMenu) => {
                                    const isChildCurrentActive = location.pathname === menu.path + childMenu.path;
                                    return (
                                        <li key={childMenu.slug}>
                                            <button
                                                className={`nav-link w-100 ${isChildCurrentActive ? 'active' : ''}`}
                                                onClick={() => {
                                                    navigate(menu.path + childMenu.path);
                                                }}
                                            >
                                                <i className={`${childMenu.icon}`}></i>
                                                <span>
                                                    <JTranslation text={childMenu.title} typeCase="pascal" />
                                                </span>
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </aside>
    );
}

export default Sidebar;
