import React from 'react';
import './withSideBar.scss';
import HeaderComponent from '../../components/header/HeaderComponent';
import Sidebar from '../../components/sidebar/Sidebar';

const withSidebar = (WrappedComponent: React.FC) => {
    return (props: any) => {
        return (
            <>
                <HeaderComponent />
                <Sidebar />
                <main id="main" className="main">
                    <section className="section h-100">
                        <WrappedComponent />
                    </section>
                </main>
            </>
        );
    };
};

export default withSidebar;
