import { useRouteError } from 'react-router-dom';
import logger from '../../utils/logger';

function ErrorPage() {
    const error: any = useRouteError();
    logger.error(error);

    return (
        <div className="bg-white rounded h-100 d-flex flex-column align-items-center justify-content-center">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
        </div>
    );
}

export default ErrorPage;
