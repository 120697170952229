import { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { _Country } from '../../../constants/staticTypes';
import { TranslationContext } from '../../../context/JTranslationProvider';
import { LANGUAGE_CHANGE_TIME, TARGET_LANGUAGE } from '../../../constants/constants';
import './LanguageSwitcher.scss';

const getFlagImage = ({ countryCode }: any) => {
    return `http://purecatamphetamine.github.io/country-flag-icons/1x1/${countryCode || 'US'}.svg`;
};

export default function LanguageSwitcher() {
    const { targetLanguage, changeTargetLanguage } = useContext(TranslationContext);
    const [languageTimeout, setLanguageTimeout] = useState<number | null>(null);

    const handleLanguageChange = (newLanguage: string) => {
        changeTargetLanguage(newLanguage);

        localStorage.setItem(TARGET_LANGUAGE, newLanguage);
        localStorage.setItem(LANGUAGE_CHANGE_TIME, new Date().getTime().toString());

        clearTimeout(languageTimeout as number);
    };

    useEffect(() => {
        const storedTargetLanguage = localStorage.getItem(TARGET_LANGUAGE);
        const storedLanguageChangeTime = localStorage.getItem(LANGUAGE_CHANGE_TIME);
        if (storedTargetLanguage) {
            changeTargetLanguage(storedTargetLanguage);
        }
        if (storedLanguageChangeTime) {
            const currentTime = new Date().getTime();
            const languageChangeTime = parseInt(storedLanguageChangeTime, 10);
            const timeElapsed = currentTime - languageChangeTime;
            const timeoutDuration = 30 * 60 * 1000; // 30 minutes in milliseconds

            if (timeElapsed < timeoutDuration) {
                const remainingTime = timeoutDuration - timeElapsed;
                setLanguageTimeout(
                    setTimeout(() => {
                        changeTargetLanguage('en');
                        localStorage.setItem(TARGET_LANGUAGE, 'en');
                    }, remainingTime) as any
                );
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            clearTimeout(languageTimeout as number);
        };
    }, [languageTimeout]);

    const selectedCountryTemplate = (option: _Country) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <img alt="flag" src={getFlagImage({ countryCode: option.code })} className={`flag flag-${option.code.toLowerCase()}`} />
                </div>
            );
        }

        return null;
    };

    const countryOptionTemplate = (option: _Country) => {
        return (
            <div className="country-item block">
                <img alt="flag" src={getFlagImage({ countryCode: option.code })} className={`flag flag-${option.code.toLowerCase()}`} />
                <span>{option.langName}</span>
            </div>
        );
    };

    const countries: _Country[] = [
        { name: 'United States', code: 'US', langKey: 'en', langName: 'English' },
        { name: 'Italy', code: 'IT', langKey: 'it', langName: 'Italian' },
        { name: 'Spain', code: 'ES', langKey: 'es', langName: 'Spanish' },
        { name: 'France', code: 'FR', langKey: 'fr', langName: 'French' },
        { name: 'Germany', code: 'DE', langKey: 'de', langName: 'German' },
        { name: 'China', code: 'CN', langKey: 'zh', langName: 'Chinese' },
        { name: 'Japan', code: 'JP', langKey: 'ja', langName: 'Japanese' },
        { name: 'India', code: 'IN', langKey: 'hi', langName: 'Hindi' },
        { name: 'ArabicLeague', code: 'AE', langKey: 'ar', langName: 'Arabic' },
        {
            name: 'Portugal',
            code: 'PT',
            langKey: 'pt-PT',
            langName: 'Portuguese',
        },
        { name: 'India', code: 'IN', langKey: 'ml', langName: 'Malayalam' },
    ];

    return (
        <div className="flex justify-content-center">
            <Dropdown
                value={targetLanguage}
                optionValue="langKey"
                onChange={(e: any) => handleLanguageChange(e.value)}
                options={countries}
                optionLabel="name"
                placeholder="Select a Country"
                valueTemplate={selectedCountryTemplate}
                itemTemplate={countryOptionTemplate}
                dropdownIcon=""
                className="customLangTranslate"
                appendTo={'self'}
                data-testid="language-dropdown"
            />
        </div>
    );
}
