import { COMING_SOON } from '../../../constants/strings';
import { JTranslation } from '../../../helpers/jTranslationHelper';
import './MlxBlockUI.scss';
import { BlockUI, BlockUIProps } from 'primereact/blockui';

type MlxBlockUIProps = BlockUIProps & {
    isComingSoon?: boolean;
    isLoading?: boolean;
    opacity?: number;
};

const MlxBlockUI = (props: Readonly<MlxBlockUIProps>) => {
    let { className, isComingSoon, blocked, isLoading, opacity, pt, ...rest } = props;
    let template = props?.template ?? <></>;

    // Comping soon
    if (isComingSoon) {
        template = (
            <div className="d-flex flex-column justify-content-center align-items-center text-secondary">
                <i className="ri-progress-3-line" style={{ fontSize: '3rem' }}></i>
                <h6 className="mt-3">
                    <JTranslation text={COMING_SOON} typeCase="pascal" />
                </h6>
            </div>
        );
    }

    // Component loader
    if (isLoading) {
        template = (
            <div className="d-flex flex-column justify-content-center align-items-center text-secondary">
                <i className="pi pi-spin pi-spinner text-primary" style={{ fontSize: '3rem' }}></i>
            </div>
        );
    }

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-block-ui';

    return (
        <BlockUI
            containerClassName={className}
            template={template}
            blocked={blocked || isLoading || isComingSoon}
            pt={
                pt ?? {
                    root: {
                        style: { height: '100%' },
                    },
                    mask: {
                        style: { opacity: opacity ?? 1 },
                    },
                }
            }
            {...rest}
        ></BlockUI>
    );
};

export default MlxBlockUI;
