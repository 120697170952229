import { useState } from 'react';
import Cropper from 'react-easy-crop';
import MlxButton from '../MlxButton/MlxButton';
import { Slider } from 'primereact/slider';
import logger from '../../../utils/logger';
import { Image } from 'primereact/image';
import { getCroppedImg } from '../../../helpers/imageCropHelper';

type MlxCropImageProps = {
    image: string;
    cropDimension?: { width: number; height: number; aspectRatio: number };
    uploadImage: (file: File) => void;
    imagePreview?: boolean;
};

const MlxCropImage = ({ image, uploadImage, cropDimension, imagePreview = false }: Readonly<MlxCropImageProps>) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [rotation, setRotation] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropProgress, setCropProgress] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | null>();

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const cropImage = () => {
        setCropProgress(true);
        getCroppedImg(image, croppedAreaPixels, rotation, { horizontal: false, vertical: false }, cropDimension)
            .then((response) => {
                if (response) {
                    setPreviewImage(response?.url);
                    uploadImage(response.file);
                }
            })
            .catch((error) => {
                logger.error('Error on cropping image', error);
            })
            .finally(() => {
                setCropProgress(false);
            });
    };

    return (
        <div className="w-100 h-100 d-flex flex-column justify-content-between">
            <div className="w-100 position-relative" style={{ height: '80%' }}>
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={cropDimension?.aspectRatio ?? 3 / 2}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    restrictPosition={false}
                    objectFit="cover"
                />
            </div>

            {imagePreview && previewImage && <Image className="my-2 w-100" preview src={previewImage} alt="Image" height="20" />}

            <div className="mt-2 mb-2 w-100 d-flex align-items-center">
                <i className="ri-zoom-out-line me-3" style={{ fontSize: '1.5rem' }}></i>
                <Slider min={0} max={4} step={0.1} className="w-100" value={zoom} onChange={(e) => setZoom(e.value as number)} />
                <i className="ri-zoom-in-line ms-2" style={{ fontSize: '1.5rem' }}></i>
            </div>
            <div className="d-flex justify-content-end align-items-center">
                <MlxButton
                    className="my-2"
                    type="button"
                    label="Crop"
                    onClick={(event) => {
                        event?.preventDefault();
                        cropImage();
                    }}
                    loading={cropProgress}
                    disabled={cropProgress}
                />
            </div>
        </div>
    );
};

export default MlxCropImage;
