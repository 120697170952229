import { UseMutationResult } from 'react-query';
import { AlertVariant, FileType } from '../constants/constants';
import { AxiosResponse } from 'axios';
import { _HttpMethods, _Payload, _ToastMessageProps } from '../constants/staticTypes';
import { UPLOAD_PHOTO_TYPE_ERROR, UPLOAD_VIDEO_TYPE_ERROR } from '../constants/strings';
import { fileUploadApi } from '../constants/apiEndPoints';

export const getNewImage = () => new Image();

// file upload api call
export const uploadFileService = (
    file: File,
    uploadMutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    onUploadSuccess: (key: string, fileUrl: string, fileType: FileType) => void,
    onUploadError: (fileType: FileType) => void,
    fileType: FileType
) => {
    let formData = new FormData();
    formData.append('selectedFile', file);
    uploadMutation.mutate(
        {
            url: fileUploadApi,
            method: _HttpMethods.POST,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: formData,
        },
        {
            onSuccess: (res) => onUploadSuccess(res.data.data.fileData.key, URL.createObjectURL(file), fileType),
            onError: () => onUploadError(fileType),
        }
    );
};

// Trigger File Upload
export const uploadFile = (
    fileType: FileType,
    file: File,
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, _Payload, unknown>,
    onSuccess: (key: string, fileUrl: string, fileType: FileType) => void,
    onError: (fileType: FileType) => void,
    setToastMessage: React.Dispatch<React.SetStateAction<_ToastMessageProps>>
) => {
    if (file.type.indexOf(fileType) !== -1) {
        uploadFileService(file, mutation, onSuccess, onError, fileType);
    } else {
        let errorMessage = fileType === FileType.IMAGE ? UPLOAD_PHOTO_TYPE_ERROR : UPLOAD_VIDEO_TYPE_ERROR;
        setToastMessage({ message: errorMessage, variant: AlertVariant.ERROR, show: true });
    }
};
