import { PropsWithChildren } from 'react';
import { CommonCtxProvider } from './CommonCtxProvider';
import { AuthCtxProvider } from './AuthCtxProvider';
import { APIOptions, PrimeReactProvider } from 'primereact/api';

const primeReactOptions: APIOptions = {
    hideOverlaysOnDocumentScrolling: false,
};

export const ContextAPI = ({ children }: PropsWithChildren) => (
    <PrimeReactProvider value={primeReactOptions}>
        <AuthCtxProvider>
            <CommonCtxProvider>{children}</CommonCtxProvider>
        </AuthCtxProvider>
    </PrimeReactProvider>
);
