import { InputText, InputTextProps } from 'primereact/inputtext';
import React, { DetailedHTMLProps, InputHTMLAttributes, useEffect } from 'react';
import './MlxSearch.scss';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';
import { useWindowSize } from 'react-use';

type MlxSearchProps = InputTextProps & {
    removeLabelOnMobile?: boolean;
    isLoading?: boolean;
    searchBarVisible?: React.Dispatch<React.SetStateAction<boolean>>;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const MlxSearch = (props: Readonly<MlxSearchProps>) => {
    let { className, removeLabelOnMobile, isLoading, searchBarVisible, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-search p-input-icon-left';

    // handle mobile view and debounce
    const { width: screenWidth } = useWindowSize();
    const [showInputText, setShowInputText] = React.useState(!(removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT));

    useEffect(() => {
        if (removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT) {
            setShowInputText(false);
        } else {
            setShowInputText(true);
        }
    }, [screenWidth, removeLabelOnMobile]);

    useEffect(() => {
        if (screenWidth <= MOBILE_BREAKPOINT) {
            searchBarVisible?.(!showInputText);
        } else {
            searchBarVisible?.(true);
        }
    }, [showInputText, screenWidth, searchBarVisible]);

    return (
        <span className={className}>
            <i
                className={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-search'}
                role={removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT ? 'button' : undefined}
                onClick={() => {
                    if (removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT) {
                        setShowInputText(!showInputText);
                    }
                }}
            />
            <InputText className={`${!showInputText ? 'show-icon-only' : ''}`} {...rest} />
        </span>
    );
};

export default MlxSearch;
