import React, { useEffect } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import './MlxButton.scss';
import { useResizeListener } from 'primereact/hooks';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';

type MlxButtonProps = ButtonProps & {
    removeLabelOnMobile?: boolean;
};

const MlxButton = (props: Readonly<MlxButtonProps>) => {
    let { className, removeLabelOnMobile, label, tooltipOptions, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-btn';

    // handle mobile view
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [bindWindowResizeListener, unbindWindowResizeListener] = useResizeListener({
        listener: (event) => {
            setScreenWidth((event.currentTarget as Window)?.innerWidth ?? window.innerWidth);
        },
    });

    useEffect(() => {
        bindWindowResizeListener();
        return () => {
            unbindWindowResizeListener();
        };
    }, [bindWindowResizeListener, unbindWindowResizeListener]);

    return (
        <Button
            label={removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT ? '' : label}
            className={className}
            // tooltip={(removeLabelOnMobile && screenWidth <= MOBILE_BREAKPOINT) ? label : undefined}
            // tooltipOptions={{ position: 'bottom', event: 'focus' }}
            {...rest}
        ></Button>
    );
};

export default MlxButton;
