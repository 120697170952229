import { Divider } from 'primereact/divider';
import { useContext, useState, useEffect } from 'react';
import { useWindowSize } from 'react-use';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import MlxSearch from '../../../components/common/MlxSearch/MlxSearch';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';
import { CommonCtx } from '../../../context/CommonCtxProvider';
import { useDebounce } from 'primereact/hooks';
import withSidebar from '../../../hoc/withSideBar/withSideBar';
import AdminRoleList from './components/RoleList';
import AddAdminRoleSidebar from './components/AddRoleSideBar';
import logger from '../../../utils/logger';

function Role() {
    const { width: screenWidth } = useWindowSize();
    const { showSideBar, setShowSideBar } = useContext(CommonCtx);

    const [searchBarVisible, setSearchBarVisible] = useState(screenWidth > MOBILE_BREAKPOINT);
    const [searchValue, debouncedSearchValue, setSearchValue] = useDebounce('', 1200);

    useEffect(() => {
        logger.log(debouncedSearchValue);
    }, [debouncedSearchValue]);

    return (
        <>
            <AddAdminRoleSidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />

            <div className="page-section">
                <span className="page-header">Manage Roles</span>
                <div className="page-content">
                    <div className="content-header">
                        <span className="header-title">{searchBarVisible ? 'Roles List' : ''}</span>
                        <div className="header-fields">
                            <MlxButton
                                className="ms-2"
                                removeLabelOnMobile={true}
                                size="small"
                                label="Add Role"
                                icon="ri-add-large-line"
                                iconPos="right"
                                onClick={() => setShowSideBar(true)}
                            />
                            <MlxSearch
                                className="ms-2 p-inputtext-sm"
                                removeLabelOnMobile={true}
                                placeholder="Search"
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                                searchBarVisible={setSearchBarVisible}
                            />
                        </div>
                    </div>
                    <Divider className="content-divider" />
                    <div className="content-body">
                        <AdminRoleList />
                    </div>
                </div>
            </div>
        </>
    );
}

export default withSidebar(Role);
