import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import * as he from 'he';
import { TranslationContext } from '../context/JTranslationProvider';
import { _TypeCase } from '../constants/staticTypes';
import { toTypeCase } from '../utils/common';
import logger from '../utils/logger';

interface TypeCase {
    typeCase?: _TypeCase;
}

interface TranslationProps extends TypeCase {
    text: string;
    type?: 'text' | 'html';
}

interface TranslationCache {
    [key: string]: string;
}

const translationCache: TranslationCache = {};

const dbName = 'translationsDB';
const storeName = 'translatedData';

/**
 * Function to open the IndexedDB database.
 * @returns Promise<IDBDatabase>
 */
function openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);

        request.onerror = () => {
            reject(new Error('Failed to open the database'));
        };

        request.onsuccess = () => {
            const db = request.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            const db = request.result;
            db.createObjectStore(storeName);
        };
    });
}

/**
 * Function to save translation to IndexedDB.
 * @param key - The cache key.
 * @param value - The translated text.
 * @returns Promise<void>
 */
function saveTranslationToDB(key: string, value: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);

        const request = store.put(value, key);

        request.onerror = () => {
            reject(new Error('Failed to save translation to IndexedDB'));
        };

        request.onsuccess = () => {
            resolve();
        };
    });
}

/**
 * Function to retrieve translation from IndexedDB.
 * @param key - The cache key.
 * @returns Promise<string | null>
 */
function getTranslationFromDB(key: string): Promise<string | null> {
    return new Promise(async (resolve, reject) => {
        const db = await openDB();
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);

        const request = store.get(key);

        request.onerror = () => {
            reject(new Error('Failed to retrieve translation from IndexedDB'));
        };

        request.onsuccess = () => {
            const translation = request.result;
            resolve(translation || null);
        };
    });
}

/**
 * JTranslation component for handling translation.
 * @param text - Text to be translated.
 * @param type - Type of translation to be handled (html or text only).
 */
export const JTranslation: React.FC<TranslationProps> = ({ text = '', type = 'text', typeCase = 'none' }) => {
    const { provider, sourceLanguage, targetLanguage, apiKey } = useContext(TranslationContext);
    const [translatedText, setTranslatedText] = useState<string | null>(null);

    useEffect(() => {
        if (text === '' || text === null) {
            setTranslatedText(text);
            return;
        }
        const cacheKey = `${text}-${targetLanguage}`;

        // If source and target languages are the same, return the original text
        if (sourceLanguage === targetLanguage) {
            setTranslatedText(text);
            return;
        }

        // If the translation is already in the cache, use it
        if (translationCache[cacheKey]) {
            setTranslatedText(translationCache[cacheKey]);
            return;
        }

        const translateText = async () => {
            try {
                // If the provider is Google, use the Google Translation API
                if (provider === 'google') {
                    const cachedTranslation = await getTranslationFromDB(cacheKey);
                    if (cachedTranslation) {
                        translationCache[cacheKey] = cachedTranslation;
                        setTranslatedText(cachedTranslation);
                        return;
                    }

                    const response = await axios.post('https://translation.googleapis.com/language/translate/v2', null, {
                        params: {
                            q: text?.toLowerCase(),
                            target: targetLanguage,
                            key: apiKey,
                            source: sourceLanguage,
                        },
                    });
                    const translatedText = response.data.data.translations[0].translatedText;
                    const decodedText = he.decode(translatedText);

                    translationCache[cacheKey] = decodedText;
                    setTranslatedText(decodedText);

                    await saveTranslationToDB(cacheKey, decodedText);
                } else {
                    // For other providers (currently unsupported), return the content as is
                    setTranslatedText(text);
                }
            } catch (error) {
                logger.error('Translation error:', error);
                setTranslatedText(text);
            }
        };
        if (text !== '') {
            translateText();
        }
    }, [text, targetLanguage, apiKey, provider, sourceLanguage]);

    /**
     * Render the translated text based on the specified type.
     * @param translatedText - The translated text.
     * @returns JSX.Element
     */
    const renderTranslatedText = (translatedText: string | null): JSX.Element => {
        let translatedTextToRender = translatedText ?? '';
        translatedTextToRender = toTypeCase(translatedTextToRender, typeCase);

        if (type === 'html') {
            return <span dangerouslySetInnerHTML={{ __html: translatedTextToRender }} />;
        }
        return <>{translatedTextToRender}</>;
    };

    return <>{renderTranslatedText(translatedText)}</>;
};

/**
 * Custom React Hook for translation.
 * @param text - The text to be translated.
 * @param targetLanguage - The target language for translation.
 * @param apiKey - The API key required for translation.
 * @param provider - The translation provider.
 * @param sourceLanguage - The source language for translation.
 * @returns TranslationResult object with the translated text or error.
 */
export async function jTranslationText({
    text = '',
    typeCase = 'none',
    translationContext,
}: {
    text: string;
    typeCase?: TypeCase['typeCase'];
    translationContext: any;
}): Promise<string | null> {
    const provider = translationContext.provider;
    const sourceLanguage = translationContext.sourceLanguage;
    const targetLanguage = translationContext.targetLanguage;
    const apiKey = translationContext.apiKey;

    let translatedText = text;

    const cacheKey = `${text}-${targetLanguage}`;

    // If source and target languages are the same, return the original text
    if (sourceLanguage === targetLanguage) {
        translatedText = toTypeCase(text, typeCase);
        return translatedText;
    }

    // If the translation is already in the cache, use it
    if (translationCache[cacheKey]) {
        translatedText = toTypeCase(translationCache[cacheKey], typeCase);
        return translatedText;
    }

    try {
        // If the provider is Google, use the Google Translation API
        if (provider === 'google') {
            const cachedTranslation = await getTranslationFromDB(cacheKey);
            if (cachedTranslation) {
                translationCache[cacheKey] = cachedTranslation;
                translatedText = toTypeCase(cachedTranslation, typeCase);
                return translatedText;
            }

            const response = await axios.post('https://translation.googleapis.com/language/translate/v2', null, {
                params: {
                    q: text?.toLowerCase(),
                    target: targetLanguage,
                    key: apiKey,
                    source: sourceLanguage,
                },
            });

            const translatedTextFromApi = response.data.data.translations[0].translatedText;
            const decodedText = he.decode(translatedTextFromApi);
            translationCache[cacheKey] = decodedText;
            translatedText = decodedText;

            await saveTranslationToDB(cacheKey, decodedText);
        } else {
            // For other providers (currently unsupported), return the content as is
            translatedText = text;
        }
    } catch (error) {
        logger.log('UNABLE TO TRANSLATED TEXT', { text });
        // @ts-ignore
        setError(error);
        translatedText = text; // Return the original text in case of error
    }

    translatedText = toTypeCase(translatedText, typeCase);

    return translatedText || '';
}
