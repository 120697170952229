import { Badge, BadgeProps } from 'primereact/badge';
import './MlxBadge.scss';
import { JTranslation } from '../../../helpers/jTranslationHelper';

type MlxBadgeProps = BadgeProps & {};

const MlxBadge = (props: Readonly<MlxBadgeProps>) => {
    let { className, value, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-badge';

    return <Badge className={className} value={<JTranslation type="text" text={value} typeCase="capitalize" />} {...rest}></Badge>;
};

export default MlxBadge;
