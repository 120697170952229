import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import MlxButton from '../../../../components/common/MlxButton/MlxButton';
import MlxSideBar from '../../../../components/common/MlxSideBar/MlxSideBar';
import { useFormik } from 'formik';
import {
    addOrganization,
    convertOrganizationData,
    initialOrganizationFormData,
    OrganizationFormSchema,
    updateOrganization,
} from '../../../../helpers/organizationHelper';
import MlxBlockUI from '../../../../components/common/MlxBlockUI/MlxBlockUI';
import { _Organization, _ToastMessageProps } from '../../../../constants/staticTypes';
import { useMutation, UseQueryResult } from 'react-query';
import HttpServiceHelper from '../../../../helpers/httpServiceHelper';
import { AxiosResponse } from 'axios';
import { AlertVariant } from '../../../../constants/constants';
import {
    ADD,
    CANCEL,
    CITY,
    CONTACT_ADDRESS,
    CONTACT_NAME,
    COUNTRY,
    EMAIL,
    INVITE_ORGANIZATION,
    ORGANIZATION_NAME,
    PRIMARY_PHONE,
    SECONDARY_PHONE,
    SEND_INVITE,
    STATE,
    UPDATE,
    WEBSITE,
    ZIP,
} from '../../../../constants/strings';
import { useTranslation } from '../../../../hooks/useTranslation';
import { JTranslation } from '../../../../helpers/jTranslationHelper';

type Props = {
    showSideBar: boolean;
    setShowSideBar: Dispatch<SetStateAction<boolean>>;
    organization?: _Organization;
    setEditOrganization: Dispatch<SetStateAction<_Organization | undefined>>;
    organizationListQueryRef: MutableRefObject<UseQueryResult<AxiosResponse<any, any>, unknown>>;
    setToastMessage: Dispatch<SetStateAction<_ToastMessageProps>>;
};

const languageTexts = {
    ADD: ADD,
    UPDATE: UPDATE,
    SEND_INVITE: SEND_INVITE,
    CANCEL: CANCEL,
};

const AddOrganizationSidebar = ({
    showSideBar,
    setShowSideBar,
    organization,
    setEditOrganization,
    organizationListQueryRef,
    setToastMessage,
}: Readonly<Props>) => {
    const organizationMutation = useMutation(HttpServiceHelper);
    const organizationFormData = convertOrganizationData(organization);
    const translatedText = useTranslation(languageTexts, 'capitalize');
    const pageMode = organizationFormData ? 'Update' : 'Add';

    const resetForm = () => {
        organizationForm.resetForm();
        setEditOrganization(undefined);
        setShowSideBar(false);
    };

    const onSuccess = (data: any) => {
        setToastMessage({
            message: data?.message ?? '',
            variant: AlertVariant.SUCCESS,
            show: true,
        });
        organizationListQueryRef?.current?.refetch();
        resetForm();
    };

    const onError = (error: string, variant: string) => {
        setToastMessage({
            message: error,
            variant,
            show: true,
        });
        resetForm();
    };

    const organizationForm = useFormik({
        enableReinitialize: true,
        initialValues: convertOrganizationData(organization) ?? initialOrganizationFormData,
        validationSchema: OrganizationFormSchema,
        onSubmit: (data) => {
            let payload = data;
            if (pageMode === 'Add') {
                delete payload.id;
                delete payload.userName; // TODO: may change

                addOrganization(organizationMutation, payload, onSuccess, onError);
            } else {
                updateOrganization(organizationMutation, payload, onSuccess, onError);
            }
        },
    });

    return (
        <MlxSideBar
            visible={showSideBar}
            position="right"
            onHide={() => resetForm()}
            content={({ hide }) => (
                <MlxBlockUI isLoading={organizationMutation?.isLoading} opacity={0.5}>
                    <div className="mlx-sidebar-header">
                        <span className="header-title">
                            <JTranslation text={INVITE_ORGANIZATION} typeCase="pascal" />
                        </span>
                        <MlxButton rounded text size="large" icon="ri-close-fill" onClick={(event) => hide(event)} className="text-dark" />
                    </div>

                    <form onSubmit={organizationForm.handleSubmit} className="mlx-sidebar-body">
                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="organizationName">
                                <JTranslation text={ORGANIZATION_NAME} typeCase="pascal" /> <span className="text-danger">*</span>
                            </label>
                            <InputText
                                className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.organizationName && organizationForm?.errors?.organizationName ? 'border-danger' : ''}`}
                                id="organizationName"
                                value={organizationForm?.values?.organizationName}
                                onChange={(event) => {
                                    organizationForm.setFieldTouched('organizationName', true);
                                    organizationForm.setFieldValue('organizationName', event.target.value, true);
                                }}
                            />
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="contactName">
                                <JTranslation text={CONTACT_NAME} typeCase="pascal" /> <span className="text-danger">*</span>
                            </label>
                            <InputText
                                className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.contactName && organizationForm?.errors?.contactName ? 'border-danger' : ''}`}
                                id="contactName"
                                value={organizationForm?.values?.contactName}
                                onChange={(event) => {
                                    organizationForm.setFieldTouched('contactName', true);
                                    organizationForm.setFieldValue('contactName', event.target.value, true);
                                }}
                            />
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="email">
                                <JTranslation text={EMAIL} typeCase="pascal" /> <span className="text-danger">*</span>
                            </label>
                            <InputText
                                className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.email && organizationForm?.errors?.email ? 'border-danger' : ''}`}
                                id="email"
                                value={organizationForm?.values?.email}
                                onChange={(event) => {
                                    if (pageMode === 'Update') return;

                                    organizationForm.setFieldTouched('email', true);
                                    organizationForm.setFieldValue('email', event.target.value?.trim()?.toLowerCase(), true);
                                }}
                                disabled={pageMode === 'Update'}
                            />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="primaryPhone">
                                    <JTranslation text={PRIMARY_PHONE} typeCase="pascal" /> <span className="text-danger">*</span>
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.primaryPhone && organizationForm?.errors?.primaryPhone ? 'border-danger' : ''}`}
                                    id="primaryPhone"
                                    value={organizationForm?.values?.primaryPhone}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('primaryPhone', true);
                                        organizationForm.setFieldValue('primaryPhone', event.target.value, true);
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="secondaryPhone">
                                    <JTranslation text={SECONDARY_PHONE} typeCase="pascal" />
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.secondaryPhone && organizationForm?.errors?.secondaryPhone ? 'border-danger' : ''}`}
                                    id="secondaryPhone"
                                    value={organizationForm?.values?.secondaryPhone}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('secondaryPhone', true);
                                        organizationForm.setFieldValue('secondaryPhone', event.target.value, true);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="address">
                                <JTranslation text={CONTACT_ADDRESS} typeCase="pascal" />
                            </label>
                            <InputTextarea
                                rows={3}
                                className={`${organizationForm?.touched?.address && organizationForm?.errors?.address ? 'border-danger' : ''}`}
                                id="address"
                                value={organizationForm?.values?.address}
                                onChange={(event) => {
                                    organizationForm.setFieldTouched('address', true);
                                    organizationForm.setFieldValue('address', event.target.value, true);
                                }}
                            />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="zip">
                                    <JTranslation text={ZIP} typeCase="pascal" />
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.zip && organizationForm?.errors?.zip ? 'border-danger' : ''}`}
                                    id="zip"
                                    value={organizationForm?.values?.zip}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('zip', true);
                                        organizationForm.setFieldValue('zip', event.target.value, true);
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="city">
                                    <JTranslation text={CITY} typeCase="pascal" />
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.city && organizationForm?.errors?.city ? 'border-danger' : ''}`}
                                    id="city"
                                    value={organizationForm?.values?.city}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('city', true);
                                        organizationForm.setFieldValue('city', event.target.value, true);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="state">
                                    <JTranslation text={STATE} typeCase="pascal" />
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.state && organizationForm?.errors?.state ? 'border-danger' : ''}`}
                                    id="state"
                                    value={organizationForm?.values?.state}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('state', true);
                                        organizationForm.setFieldValue('state', event.target.value, true);
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="country">
                                    <JTranslation text={COUNTRY} typeCase="pascal" />
                                </label>
                                <InputText
                                    className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.country && organizationForm?.errors?.country ? 'border-danger' : ''}`}
                                    id="country"
                                    value={organizationForm?.values?.country}
                                    onChange={(event) => {
                                        organizationForm.setFieldTouched('country', true);
                                        organizationForm.setFieldValue('country', event.target.value, true);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="website">
                                <JTranslation text={WEBSITE} typeCase="pascal" />
                            </label>
                            <InputText
                                className={`custom-input-text p-inputtext-sm ${organizationForm?.touched?.website && organizationForm?.errors?.website ? 'border-danger' : ''}`}
                                id="website"
                                value={organizationForm?.values?.website}
                                onChange={(event) => {
                                    organizationForm.setFieldTouched('website', true);
                                    organizationForm.setFieldValue('website', event.target.value, true);
                                }}
                            />
                        </div>
                    </form>

                    <div className="mlx-sidebar-footer">
                        <MlxButton
                            label={translatedText.CANCEL}
                            onClick={() => {
                                organizationForm.resetForm();
                                setShowSideBar(false);
                            }}
                            icon="ri-close-large-line"
                            iconPos="right"
                            size="small"
                            outlined
                            type="button"
                        />
                        <MlxButton
                            type="button"
                            className="ms-2"
                            label={pageMode === 'Add' ? translatedText.SEND_INVITE : translatedText.UPDATE}
                            icon={pageMode === 'Add' ? 'ri-send-plane-line' : 'ri-pencil-line'}
                            iconPos="right"
                            size="small"
                            onClick={() => organizationForm.submitForm()}
                        />
                    </div>
                </MlxBlockUI>
            )}
        />
    );
};

export default AddOrganizationSidebar;
