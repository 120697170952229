import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../routes/allRoutes';
import { useEffect, useState } from 'react';
import { _GetStartedWizard, GetStartedWizardFormInitialValues } from '../../helpers/getStartedHelper';
import { _GoogleAddress, _S3ImageResponse, _ToastMessageProps } from '../../constants/staticTypes';
import { defaultToastData, initialAddress } from '../../helpers/commonHelper';
import MlxToast from '../../components/common/MlxToast/MlxToast';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

const GetStarted = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const currentStep = location.pathname;

    const [locationFormData, setLocationFormData] = useState<_GoogleAddress>(initialAddress);
    const [getStartedWizardForm, setGetStartedWizardForm] = useState<_GetStartedWizard>(GetStartedWizardFormInitialValues);
    const [toastMessage, setToastMessage] = useState<_ToastMessageProps>(defaultToastData);
    const [webLogo, setWebLogo] = useState<_S3ImageResponse>({
        imageKey: getStartedWizardForm?.webLogo ?? null,
        imageUrl: null, // need to get the image url using key
    });
    const [mobileLogo, setMobileLogo] = useState<_S3ImageResponse>({
        imageKey: getStartedWizardForm?.mobileLogo ?? null,
        imageUrl: null, // need to get the image url using key
    });

    useEffect(() => {
        if (!['step2', 'step3', 'step4'].includes(params?.step ?? '')) {
            navigate(routes.login, { replace: true });
        }
    }, [navigate, params]);

    return (
        <>
            <MlxToast
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(defaultToastData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />
            <div className="login-page-container row m-0 h-100 w-100">
                {currentStep === routes.onBoardCreateVenue + 'step2' && (
                    <Step2
                        getStartedWizardForm={getStartedWizardForm}
                        setGetStartedWizardForm={setGetStartedWizardForm}
                        locationFormData={locationFormData}
                        setLocationFormData={setLocationFormData}
                    />
                )}
                {currentStep === routes.onBoardCreateVenue + 'step3' && (
                    <Step3
                        getStartedWizardForm={getStartedWizardForm}
                        setGetStartedWizardForm={setGetStartedWizardForm}
                        webLogo={webLogo}
                        setWebLogo={setWebLogo}
                        mobileLogo={mobileLogo}
                        setMobileLogo={setMobileLogo}
                    />
                )}
                {currentStep === routes.onBoardCreateVenue + 'step4' && (
                    <Step4
                        getStartedWizardForm={getStartedWizardForm}
                        setGetStartedWizardForm={setGetStartedWizardForm}
                        setToastMessage={setToastMessage}
                    />
                )}
            </div>
        </>
    );
};

export default GetStarted;
