import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import '../GetStarted.scss';
import { routes } from '../../../routes/allRoutes';
import { useEffect } from 'react';
import {
    domainRef,
    _GetStartedWizard,
    getStartedWizardOneFormSchema,
    addVenue,
    getStartedWizardOTwoFormSchema,
    _GetStartedWizardFour,
} from '../../../helpers/getStartedHelper';
import { useFormik } from 'formik';
import { AlertVariant, DOMAIN_NAME } from '../../../constants/constants';
import CopyRight from '../../common/CopyRight';
import { useDebounce } from 'primereact/hooks';
import {
    BACK,
    CHECKING_DOMAIN_AVAILABILITY,
    CREATE_FIRST_AVENUE,
    CREATE_THIRD_AVENUE_MSG,
    CREATE_FIRST_AVENUE_WARNING,
    FINISH,
    PREFERRED_VENUE_URL,
    STEP_4,
} from '../../../constants/strings';
import { useTranslation } from '../../../hooks/useTranslation';
import { _ToastMessageProps } from '../../../constants/staticTypes';
import logger from '../../../utils/logger';
import { useMutation } from 'react-query';
import HttpServiceHelper from '../../../helpers/httpServiceHelper';

type Props = {
    getStartedWizardForm: _GetStartedWizard;
    setGetStartedWizardForm: React.Dispatch<React.SetStateAction<_GetStartedWizard>>;
    setToastMessage: React.Dispatch<React.SetStateAction<_ToastMessageProps>>;
};

const languageTexts = {
    STEP_4: STEP_4,
    CREATE_FIRST_AVENUE: CREATE_FIRST_AVENUE,
    CREATE_THIRD_AVENUE_MSG: CREATE_THIRD_AVENUE_MSG,
    FINISH: FINISH,
    CHECKING_DOMAIN_AVAILABILITY: CHECKING_DOMAIN_AVAILABILITY,
    CREATE_FIRST_AVENUE_WARNING: CREATE_FIRST_AVENUE_WARNING,
    PREFERRED_VENUE_URL: PREFERRED_VENUE_URL,
    BACK: BACK,
};

function Step4({ getStartedWizardForm, setGetStartedWizardForm, setToastMessage }: Readonly<Props>) {
    const domainSuffix = `.${DOMAIN_NAME}`;
    const navigate = useNavigate();
    const venueMutation = useMutation(HttpServiceHelper);
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const getStartedWizardFormFour: _GetStartedWizardFour = {
        domainName: getStartedWizardForm.domainName ?? '',
    };
    const [domainName, debouncedDomainName, setDomainName] = useDebounce('', 1000);

    const onSuccess = (data: any) => {
        setToastMessage({
            message: data?.message ?? '',
            variant: AlertVariant.SUCCESS,
            show: true,
        });

        navigate(routes.dashboard, { replace: true });
    };

    const onError = (error: string, variant: string) => {
        setToastMessage({
            message: error,
            variant,
            show: true,
        });
    };

    const formSubmit = (data: _GetStartedWizardFour) => {
        logger.info(getStartedWizardForm);

        const payload: _GetStartedWizard = { ...getStartedWizardForm, ...data };
        setGetStartedWizardForm(payload);

        addVenue(venueMutation, payload, onSuccess, onError);
    };

    const getStartedWizardFormik = useFormik({
        enableReinitialize: true,
        initialValues: getStartedWizardFormFour,
        validationSchema: getStartedWizardOneFormSchema,
        onSubmit: (data) => formSubmit(data),
    });

    useEffect(() => {
        if (debouncedDomainName) {
            getStartedWizardFormik.setFieldTouched('domainName', true);
        }
        getStartedWizardFormik.setFieldValue('domainName', debouncedDomainName, true);
    }, [debouncedDomainName]);

    useEffect(() => {
        getStartedWizardOTwoFormSchema.validate(getStartedWizardForm).catch((error) => {
            navigate(routes.onBoardCreateVenue + 'step2', { replace: true });
        });
    }, [navigate]);

    return (
        <>
            <div className="col-md-7 d-none d-md-block d-flex flex-column align-items-center justify-content-center onboard-bg-img"></div>
            <div className="col-md-5 bg-white d-flex flex-column align-items-center justify-content-between shadow">
                <div></div>
                <form className="w-100 px-4 py-3" onSubmit={getStartedWizardFormik.handleSubmit}>
                    <div className="step-container">
                        <span className="step-number">{translatedText.STEP_4}</span>
                        <span className="step-title">{translatedText.CREATE_FIRST_AVENUE}</span>
                        <span className="step-note">{translatedText.CREATE_THIRD_AVENUE_MSG}</span>
                    </div>

                    <div className="step2-container-1">
                        <div className="venue-url-container rounded">
                            <div className="mt-2 note d-flex flex-row align-items-center">
                                <i className="pi pi-info-circle" />
                                <span className="ms-1">{translatedText.CREATE_FIRST_AVENUE_WARNING}</span>
                            </div>
                            <div className="form-field d-flex flex-column mt-2">
                                <label htmlFor="venue-url">{translatedText.PREFERRED_VENUE_URL}</label>
                                <div className="mt-1 d-flex flex-row align-items-center">
                                    <InputText
                                        className={`custom-input-text p-inputtext-sm ${getStartedWizardFormik?.touched?.domainName && getStartedWizardFormik?.errors?.domainName ? 'border-danger' : ''} ${domainRef?.isAvailable && !getStartedWizardFormik?.errors?.domainName ? 'text-success border-success' : ''}`}
                                        autoComplete="off"
                                        value={domainName}
                                        onChange={(event) => setDomainName(event?.target?.value?.trim()?.toLowerCase())}
                                    />
                                    <span
                                        className={`ms-1 ${domainRef?.isAvailable && !getStartedWizardFormik?.errors?.domainName ? 'text-success' : 'text-secondary'}`}
                                    >
                                        {domainSuffix}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="mt-2 d-flex flex-row align-items-center text-primary"
                                style={{
                                    visibility: domainRef?.isProgress ? 'visible' : 'hidden',
                                }}
                            >
                                <i className="pi pi-spin pi-spinner" />
                                <span className="ms-1">{translatedText.CHECKING_DOMAIN_AVAILABILITY}</span>
                            </div>
                        </div>
                    </div>

                    <div className="step-container-footer">
                        <MlxButton
                            label={translatedText.BACK}
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(routes.onBoardCreateVenue + 'step3');
                            }}
                            icon="ri-arrow-left-line"
                            iconPos="left"
                            outlined
                            raised
                            disabled={false}
                        />
                        <MlxButton
                            type="submit"
                            className="ms-2"
                            label={translatedText.FINISH}
                            icon="ri-check-line"
                            iconPos="right"
                            raised
                            disabled={domainRef?.isProgress || !getStartedWizardFormik.isValid || venueMutation?.isLoading}
                            loading={venueMutation?.isLoading}
                        />
                    </div>
                </form>

                <CopyRight />
            </div>
        </>
    );
}

export default Step4;
