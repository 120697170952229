import { Outlet } from 'react-router-dom';
import { ContextAPI } from './context/ContextApi';

function App() {
    return (
        <ContextAPI>
            <Outlet />
        </ContextAPI>
    );
}

export default App;
