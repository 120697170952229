import React from 'react';
import { InputSwitch, InputSwitchProps } from 'primereact/inputswitch';
import './MlxInputSwitch.scss';

const MlxInputSwitch: React.FC<InputSwitchProps> = (props: InputSwitchProps) => {
    let { className, ...rest } = props;

    // Update class name
    if (!className) {
        className = '';
    }
    className += ' mlx-input-switch';

    return <InputSwitch className={className} {...rest}></InputSwitch>;
};

export default MlxInputSwitch;
