import { createBrowserRouter } from 'react-router-dom';
import Dashboard from '../pages/dashboard/Dashboard';
import ErrorPage from '../pages/common/ErrorPage';
import Login from '../pages/login/Login';
import App from '../App';
import AuthRoute from './authRoute';
import GetStarted from '../pages/get-started/GetStarted';
import OrganizationList from '../pages/organization/list/Organization';
import AdminStaff from '../pages/staff/list/Staff';
import OrganizationUser from '../pages/organization/user/User';
import AdminRole from '../pages/staff/roles/Role';
import OrganizationRole from '../pages/organization/roles/Role';
import OrganizationPermission from '../pages/organization/permission/Permission';
import AdminPermission from '../pages/staff/permission/Permission';
import RedirectPage from '../pages/common/RedirectPage';
import RegisterAccount from '../pages/register-account/RegisterAccount';

export const browserRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/',
                element: <AuthRoute />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: '/',
                        element: <Dashboard />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: '/organization',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: 'list',
                                element: <OrganizationList />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'users',
                                element: <OrganizationUser />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'roles',
                                element: <OrganizationRole />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'permissions',
                                element: <OrganizationPermission />,
                                errorElement: <ErrorPage />,
                            },
                        ],
                    },
                    {
                        path: '/venues',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: 'list',
                                element: <OrganizationList />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'users',
                                element: <OrganizationUser />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'permissions',
                                element: <OrganizationPermission />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'fbmenu',
                                element: <OrganizationList />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'schedules',
                                element: <OrganizationList />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'settings',
                                element: <OrganizationList />,
                                errorElement: <ErrorPage />,
                            },
                        ],
                    },
                    {
                        path: '/staff',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: 'list',
                                element: <AdminStaff />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'roles',
                                element: <AdminRole />,
                                errorElement: <ErrorPage />,
                            },
                            {
                                path: 'permissions',
                                element: <AdminPermission />,
                                errorElement: <ErrorPage />,
                            },
                        ],
                    },
                    {
                        path: '/subscriptions',
                        element: <Dashboard />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: '/report',
                        element: <Dashboard />,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: '/help',
                        element: <Dashboard />,
                        errorElement: <ErrorPage />,
                    },
                    // {
                    //     path: '/get-started/step2',
                    //     element: <GetStarted />,
                    //     errorElement: <ErrorPage />,
                    // },
                    {
                        path: '/get-started',
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: 'create-venue/:step',
                                element: <GetStarted />,
                                errorElement: <ErrorPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: '/login',
                element: <Login />,
                errorElement: <ErrorPage />,
            },
            {
                path: '*',
                element: <Login />, // re-direct to login, in case of in-valid URL
            },
            {
                path: '/rp/:shortCode',
                element: <RedirectPage />,
            },
            {
                path: '/register-account/',
                element: <RegisterAccount />,
            },
        ],
    },
]);

export const routes = {
    login: '/login',
    forgotPassword: '#',

    dashboard: '/',
    orgManagement: '/org-management',
    venueManagement: '/venue-management',
    userManagement: '/user-management',
    permissions: '/permissions',
    subscriptions: '/subscriptions',
    report: '/report',
    help: '/help',
    onBoardCreateVenue: '/get-started/create-venue/',
};
