import { Dialog, DialogProps } from 'primereact/dialog';
import './MlxDialog.scss';

type _CustomDialogProps = DialogProps & {
    children: React.ReactNode;
};

const MlxDialog = (props: Readonly<_CustomDialogProps>) => {
    let { className, onHide, children, draggable, style, ...rest } = props;

    if (!className) {
        className = 'mlx-dialog';
    }

    return (
        <Dialog
            onHide={onHide}
            className={className}
            draggable={draggable ?? false}
            style={
                style ?? {
                    minWidth: '35rem',
                }
            }
            {...rest}
        >
            {children}
        </Dialog>
    );
};

export default MlxDialog;
