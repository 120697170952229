const getOrigin = () => window.location.origin;

const allowedOrigins = ['http://localhost:3000', 'https://dev-admin.micrologx.app'];

const logger = {
    log: (...args: any[]) => {
        if (allowedOrigins.includes(getOrigin())) {
            console.log(...args);
        }
    },
    info: (...args: any[]) => {
        if (allowedOrigins.includes(getOrigin())) {
            console.info(...args);
        }
    },
    error: (...args: any[]) => {
        if (allowedOrigins.includes(getOrigin())) {
            console.error(...args);
        }
    },
    warn: (...args: any[]) => {
        if (allowedOrigins.includes(getOrigin())) {
            console.warn(...args);
        }
    },
    debug: (...args: any[]) => {
        if (allowedOrigins.includes(getOrigin())) {
            console.debug(...args);
        }
    },
};

export default logger;
