import { AxiosError } from 'axios';
import { capitalize, lowerCase, upperCase, startCase } from 'lodash';
import { _ErrorMessage, _TypeCase } from '../constants/staticTypes';
import { UNKNOWN_ERROR } from '../constants/strings';
import logger from './logger';

export const toTypeCase = (inputString: string, typeCase: _TypeCase = 'none') => {
    if (!inputString) return '';

    switch (typeCase) {
        case 'upper':
            return upperCase(inputString);
        case 'lower':
            return lowerCase(inputString);
        case 'capitalize':
            return capitalize(inputString);
        case 'pascal':
            return startCase(inputString);
        case 'none':
        default:
            return inputString;
    }
};

export const validateDomain = (domain: string): boolean => {
    /**
     * Regular expression to validate a domain string.
     * It checks for the following:
     * - The string does not start with '://'
     * - The string contains one or more subdomains separated by dots
     * - Each subdomain consists of alphanumeric characters and hyphens
     * - The top-level domain (TLD) is alphabetic and is 2 to 63 characters long
     */
    const domainRegex = /^(?!:\/\/)([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i;

    // Check if the domain string is within the valid length range (1 to 63 characters)
    const isValidLength = domain.length >= 1 && domain.length <= 63;

    // Test the domain string against the regular expression and return the result
    return isValidLength && domainRegex.test(domain);
};

// get error message from axios response
export const getErrorMessage = (error: unknown) => {
    let axiosError = error as AxiosError;
    let errorData = {
        ...(axiosError.response as _ErrorMessage),
    };

    if (axiosError.response?.status === 422) {
        if (errorData?.data?.errors) {
            return errorData.data?.errors[0]?.message;
        }
        return UNKNOWN_ERROR;
    } else {
        return errorData.data?.message !== undefined ? errorData.data.message : UNKNOWN_ERROR;
    }
};

export const replaceMessageValues = (str: string, values: any[]): string => {
    const regex = /TXT/g;
    const matches = str.match(regex);
    if (matches && matches.length !== values.length) {
        logger.error("The number of 'TXT' occurrences must match the number of values provided");
        return str;
    }

    return str.replace(regex, (_) => values.shift() || '');
};
