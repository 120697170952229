import { useState, useEffect, useContext } from 'react';
import { TranslationContext } from '../context/JTranslationProvider';
import { jTranslationText } from '../helpers/jTranslationHelper';
import logger from '../utils/logger';

// Custom hook for translating text
export function useTranslation(initialTexts: { [key: string]: string }, typeCase?: 'upper' | 'lower' | 'capitalize' | 'pascal' | 'none') {
    const translationContext = useContext(TranslationContext);
    const [translatedText, setTranslatedText] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        async function fetchTranslation() {
            try {
                const translatedTexts: { [key: string]: string } = {};
                await Promise.all(
                    Object.entries(initialTexts).map(async ([key, text]) => {
                        const translatedText = await jTranslationText({
                            text,
                            typeCase,
                            translationContext,
                        });
                        translatedTexts[key] = translatedText ?? text;
                    })
                );
                setTranslatedText(translatedTexts);
            } catch (error) {
                logger.error('Error translating text:', error);
                setTranslatedText(initialTexts);
            }
        }

        fetchTranslation();
    }, [initialTexts, translationContext, typeCase]); // Runs whenever initialText or translationContext changes

    return translatedText;
}
