import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import MlxButton from '../../../../components/common/MlxButton/MlxButton';
import MlxSideBar from '../../../../components/common/MlxSideBar/MlxSideBar';
import { Avatar } from 'primereact/avatar';
import profile from '../../../../assets/images/profile.png';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import MlxDropDown from '../../../../components/common/MlxDropDown/MlxDropDown';
import AdminPermissionsGroups from '../../../../mocks/permission-user.json';
import AdminRoles from '../../../../mocks/roles.json';

type Props = {
    showSideBar: boolean;
    setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddStaffSidebar = ({ showSideBar, setShowSideBar }: Readonly<Props>) => {
    const [sendPasswordLink, setSendPasswordLink] = useState<boolean>(false);

    return (
        <MlxSideBar
            visible={showSideBar}
            position="right"
            onHide={() => setShowSideBar(false)}
            content={({ hide }) => (
                <>
                    <div className="mlx-sidebar-header">
                        <span className="header-title">Add Staff</span>
                        <MlxButton rounded text size="large" icon="ri-close-fill" onClick={(event) => hide(event)} className="text-dark" />
                    </div>

                    <form className="mlx-sidebar-body add-staff-form">
                        <div className="mb-5 d-flex flex-column profile-image-form">
                            <Avatar image={profile} imageFallback={profile} size="xlarge" shape="circle">
                                <Button
                                    raised
                                    className="edit-icon"
                                    icon="pi pi-pencil"
                                    rounded
                                    aria-label="Edit"
                                    onClick={(event) => event.preventDefault()}
                                />
                                {/* <Button raised className="add-icon" icon="pi pi-plus" rounded aria-label="Add" /> */}
                                <Button
                                    raised
                                    className="delete-icon"
                                    icon="pi pi-trash"
                                    rounded
                                    aria-label="Delete"
                                    onClick={(event) => event.preventDefault()}
                                />
                            </Avatar>
                        </div>

                        <div className="mb-5 d-flex flex-column username-field">
                            <label className="mb-1" htmlFor="username">
                                Username <span className="text-danger">*</span>
                            </label>
                            <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            <div className="mt-3 d-flex flex-row align-items-center">
                                <Checkbox
                                    inputId="send-password-link"
                                    name="send-password-link"
                                    checked={sendPasswordLink}
                                    onChange={(e) => setSendPasswordLink(e.checked ? true : false)}
                                />
                                <label className="ms-2" htmlFor="send-password-link">
                                    Send Password Link
                                </label>
                            </div>
                        </div>

                        <div className="mb-3 d-flex flex-column">
                            <label className="mb-1" htmlFor="username">
                                Display Name <span className="text-danger">*</span>
                            </label>
                            <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    First Name <span className="text-danger">*</span>
                                </label>
                                <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    Last Name
                                </label>
                                <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    Email <span className="text-danger">*</span>
                                </label>
                                <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            </div>
                            <div className="col-md-6 mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    Mobile
                                </label>
                                <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            </div>
                        </div>

                        <Fieldset className="custom-p-fieldset mt-4 mb-3" legend="Organization">
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Organization <span className="text-danger">*</span>
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="Org1"
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Permission Group
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="Admin"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Roles <span className="text-danger">*</span>
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="Manager"
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Hire Date
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="02/02/2023"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        POS <span className="text-danger">*</span>
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                        value="POS1"
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-6 mb-3 d-flex flex-column justify-content-end">
                                    <div className="mt-2 d-flex flex-row justify-content-end align-items-center">
                                        <MlxButton
                                            severity="danger"
                                            outlined
                                            size="small"
                                            iconPos="right"
                                            icon="pi pi-trash"
                                            label="Delete"
                                            onClick={(event) => event.preventDefault()}
                                        />
                                        <MlxButton
                                            className="ms-3"
                                            size="small"
                                            iconPos="right"
                                            icon="pi pi-pencil"
                                            label="Edit"
                                            onClick={(event) => event.preventDefault()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fieldset>

                        <Fieldset className="custom-p-fieldset mb-3" legend="Organization">
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Organization <span className="text-danger">*</span>
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                    />
                                </div>
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Permission Group
                                    </label>
                                    <MlxDropDown dropDownSize="small" options={AdminPermissionsGroups} optionLabel="permissionRoleName" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Roles <span className="text-danger">*</span>
                                    </label>
                                    <MlxDropDown dropDownSize="small" options={AdminRoles} optionLabel="name" filter />
                                </div>
                                <div className="col-md-6 mb-3 d-flex flex-column">
                                    <label className="mb-1" htmlFor="username">
                                        Hire Date
                                    </label>
                                    <InputText
                                        className="custom-input-text p-inputtext-sm"
                                        id="username"
                                        aria-describedby="username-help"
                                    />
                                </div>
                            </div>
                            <div className="mb-3 d-flex flex-column">
                                <label className="mb-1" htmlFor="username">
                                    POS <span className="text-danger">*</span>
                                </label>
                                <InputText className="custom-input-text p-inputtext-sm" id="username" aria-describedby="username-help" />
                            </div>
                            <div className="mt-4 d-flex flex-row justify-content-end align-items-center">
                                <MlxButton
                                    outlined
                                    className="me-2"
                                    size="small"
                                    iconPos="right"
                                    icon="pi pi-times"
                                    label="Reset"
                                    onClick={(event) => event.preventDefault()}
                                />
                                <MlxButton
                                    size="small"
                                    iconPos="right"
                                    icon="pi pi-plus"
                                    label="Add"
                                    onClick={(event) => event.preventDefault()}
                                />
                            </div>
                        </Fieldset>
                    </form>

                    <div className="mlx-sidebar-footer">
                        <MlxButton
                            label="Cancel"
                            onClick={() => setShowSideBar(false)}
                            icon="ri-close-large-line"
                            iconPos="right"
                            size="small"
                            outlined
                        />
                        <MlxButton
                            className="ms-2"
                            label="Save"
                            onClick={() => {
                                setShowSideBar(false);
                            }}
                            icon="ri-check-line"
                            iconPos="right"
                            size="small"
                        />
                    </div>
                </>
            )}
        />
    );
};

export default AddStaffSidebar;
