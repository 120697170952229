import React, { useContext, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import MlxButton from '../../components/common/MlxButton/MlxButton';
import { Checkbox } from 'primereact/checkbox';
import './login.scss';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/micrologx_logo.png';
import { routes } from '../../routes/allRoutes';
import { ADMIN_ID_HEADER, APP_NAME, APP_YEAR, LANGUAGE_CHANGE_TIME, ORG_ID_HEADER, TARGET_LANGUAGE } from '../../constants/constants';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { useMutation, useQueryClient } from 'react-query';
import { clearCognitoData, getCognitoUserName, getUserDetailsByUserName, userCustomAttributes, userLogin } from '../../helpers/authHelper';
import {
    FOOTER_BRAND,
    FORGOT_PASSWORD,
    INACTIVE_USER_ERROR,
    LOGIN,
    LOGIN_ERROR,
    PASSWORD,
    REMEMBER_ME,
    USERNAME,
} from '../../constants/strings';
import { _User, _UserType } from '../../constants/staticTypes';
import { AuthCtx } from '../../context/AuthCtxProvider';
import { useTranslation } from '../../hooks/useTranslation';
import { JTranslation } from '../../helpers/jTranslationHelper';
import logger from '../../utils/logger';

const languageTexts = {
    REMEMBER_ME: REMEMBER_ME,
    USERNAME: USERNAME,
    PASSWORD: PASSWORD,
    FORGOT_PASSWORD: FORGOT_PASSWORD,
    FOOTER_BRAND: FOOTER_BRAND,
    LOGIN: LOGIN,
};

const Login = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const messageRef = useRef<Messages>(null);
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const { setIsSignedIn, setUserType, setUserData } = useContext(AuthCtx);

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        // prefetch cognito user-name
        let cognitoUsername = username;
        getCognitoUserName(queryClient, username)
            .then((response) => {
                cognitoUsername = response?.data?.data?.preferredUserName ?? username;
            })
            .catch((error) => {
                logger.info('Error on fetching user-name', error);
            })
            .finally(() => {
                setIsLoading(false);
                loginMutation.mutate({ username: cognitoUsername, password });
            });
    };

    const onLoginSuccess = async (response: any) => {
        // update admin id/org id
        const userType = (await userCustomAttributes(response, 'custom:userType')) as _UserType;
        const adminId = await userCustomAttributes(response, 'custom:userIdDb');
        const orgId = await userCustomAttributes(response, 'custom:organizationId');
        localStorage.setItem(ADMIN_ID_HEADER, adminId ?? '');
        localStorage.setItem(ORG_ID_HEADER, orgId ?? '');

        // user details
        const userName = response?.username ?? '';
        const userDataResponse = await getUserDetailsByUserName(queryClient, userType, userName);
        const userData = userDataResponse?.data?.data as _User;

        // Update language
        localStorage.setItem(TARGET_LANGUAGE, userData?.preferredLangKey ?? 'en');
        localStorage.setItem(LANGUAGE_CHANGE_TIME, new Date().getTime().toString());

        if (!userData?.isActive) {
            return Promise.reject(new Error(`EXCEPTION: ${INACTIVE_USER_ERROR}`));
        }

        // TODO: update permissions
        // const permissionListResponse = await getPermissionList(queryClient);
        // const parseAdminPermissions = simplifyUsePermissions(userData?.staffPermissionRoles)
        // const parseOrgPermissions = simplifyUsePermissions(userData?.organizationPermissions)

        setIsSignedIn(true);
        setUserType(userType);
        setUserData(userData);

        // If org admin and don't have any venue, redirect to venue/tenant onboarding
        if (userType === 'organizationAdmin') {
            if (userData?.venueCount <= 0) {
                navigate(routes.onBoardCreateVenue + 'step2', { replace: true });
                return;
            }
        }

        // TODO: handle route path by url
        navigate(routes.dashboard, { replace: true });
    };

    const onLoginError = (error: Error) => {
        clearCognitoData();

        const errorMessage = error?.message;
        const isException = errorMessage?.includes('EXCEPTION:');
        const cleanedMessage = isException ? errorMessage.replace('EXCEPTION:', '') : LOGIN_ERROR;

        if (messageRef?.current) {
            messageRef.current.clear();
            messageRef.current.show({
                severity: 'error',
                life: 4500,
                content: (
                    <small>
                        <JTranslation text={cleanedMessage} typeCase="capitalize" />
                    </small>
                ),
                closable: false,
            });
        }
    };

    const loginMutation = useMutation(userLogin, {
        onSuccess: onLoginSuccess,
        onError: onLoginError,
    });

    return (
        <div className="login-page-container row m-0 h-100 w-100">
            <div className="col-md-6 d-none d-md-block d-flex flex-column align-items-center justify-content-center login-page-img"></div>
            <div className="col-md-6 bg-white d-flex flex-column align-items-center justify-content-between shadow">
                <div></div>
                <div>
                    <img className="w-auto" src={logo} alt="logo" />

                    <form className="w-100 login-form d-flex flex-column align-items-center" onSubmit={handleLogin}>
                        <InputText
                            className="mt-3 w-100 p-inputtext-sm"
                            value={username}
                            onChange={(e) => setUsername(e.target.value?.trim()?.toLowerCase())}
                            autoComplete="off"
                            placeholder={translatedText.USERNAME}
                        />

                        <div className="w-100 mt-3 position-relative d-flex align-items-center">
                            <InputText
                                className="w-100 p-inputtext-sm"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="off"
                                placeholder={translatedText.PASSWORD}
                            />
                            <Button
                                className="position-absolute end-0"
                                icon={showPassword ? 'ri-eye-off-fill' : 'ri-eye-fill'}
                                rounded
                                text
                                size="large"
                                severity="secondary"
                                aria-label="Toggle password visibility"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setShowPassword(!showPassword);
                                }}
                                pt={{
                                    root: {
                                        style: {
                                            border: 'none',
                                            background: 'transparent',
                                            boxShadow: 'none',
                                        },
                                    },
                                }}
                            />
                        </div>

                        <div className="w-100 remember-me my-3 d-flex flex-row align-items-center">
                            <Checkbox
                                className="size-small"
                                inputId="remember-me"
                                name="remember-me"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(!!e.checked)}
                            />
                            <small>
                                <label className="ms-2" htmlFor="remember-me">
                                    {translatedText.REMEMBER_ME}
                                </label>
                            </small>
                        </div>

                        <div className="w-100 d-flex align-items-center">
                            <Messages className="w-100" ref={messageRef} pt={{ wrapper: { style: { padding: '0.5em 1rem' } } }} />
                        </div>

                        <MlxButton
                            type="submit"
                            label={translatedText.LOGIN}
                            className="mt-3 w-100"
                            color="primary"
                            raised
                            loading={loginMutation.isLoading || isLoading}
                        />

                        <small className="w-100 mt-2 d-flex flex-row justify-content-end">
                            <a className="text-secondary" href={routes.forgotPassword}>
                                {translatedText.FORGOT_PASSWORD}
                            </a>
                        </small>
                    </form>
                </div>

                <small className="mb-2 d-flex flex-row justify-content-center align-items-center">
                    <i className="ri-copyright-line"></i>
                    <span className="ms-1">
                        {APP_YEAR} {APP_NAME}, {translatedText.FOOTER_BRAND}
                    </span>
                </small>
            </div>
        </div>
    );
};

export default Login;
