import { useContext, useEffect, useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import { useDebounce } from 'primereact/hooks';
import { useWindowSize } from 'react-use';
import MlxButton from '../../../components/common/MlxButton/MlxButton';
import MlxSearch from '../../../components/common/MlxSearch/MlxSearch';
import { MOBILE_BREAKPOINT } from '../../../constants/constants';
import { CommonCtx } from '../../../context/CommonCtxProvider';
import withSidebar from '../../../hoc/withSideBar/withSideBar';
import AddOrganizationSidebar from './components/AddOrganizationSidebar';
import OrganizationList from './components/OrganizationList';
import { QueryClient, useQuery } from 'react-query';
import { ORGANIZATION_LIST } from '../../../constants/queryKeys';
import { _DatatablePageEvent, _Organization, _Pagination, _ToastMessageProps } from '../../../constants/staticTypes';
import { defaultPaginationEvent, defaultToastData } from '../../../helpers/commonHelper';
import { getOrganizationList } from '../../../helpers/organizationHelper';
import MlxToast from '../../../components/common/MlxToast/MlxToast';
import { useTranslation } from '../../../hooks/useTranslation';
import { INVITE_ORGANIZATION, MANAGE_ORGANIZATION, ORG_LIST, SEARCH } from '../../../constants/strings';
import logger from '../../../utils/logger';

const languageTexts = {
    MANAGE_ORGANIZATION: MANAGE_ORGANIZATION,
    ORG_LIST: ORG_LIST,
    INVITE_ORGANIZATION: INVITE_ORGANIZATION,
    SEARCH: SEARCH,
};
const searchColumns = ['organizationName', 'contactDetails.emailId', 'contactDetails.primaryPhone', 'branding.website'];

function Organization() {
    const queryClient = new QueryClient();
    const { width: screenWidth } = useWindowSize();
    const { showSideBar, setShowSideBar } = useContext(CommonCtx);
    const translatedText = useTranslation(languageTexts, 'capitalize');

    const [toastMessage, setToastMessage] = useState<_ToastMessageProps>(defaultToastData);
    const [searchBarVisible, setSearchBarVisible] = useState(screenWidth > MOBILE_BREAKPOINT);
    const [searchValue, debouncedSearchValue, setSearchValue] = useDebounce('', 1200);
    const [dataTableEvent, setDataTableEvent] = useState<_DatatablePageEvent>(defaultPaginationEvent);
    const [organizationList, setOrganizationList] = useState<_Organization[]>([]);
    const [editOrganization, setEditOrganization] = useState<_Organization>();

    const organizationListQuery = useQuery({
        queryKey: ORGANIZATION_LIST,
        queryFn: () => getOrganizationList(queryClient, (dataTableEvent.page ?? 0) + 1, dataTableEvent.rows, dataTableEvent.search),
        onSuccess: (response) => {
            const paginationData = response?.data?.data?.pagination as _Pagination;
            const responseData = response?.data?.data?.lists as _Organization[];
            setOrganizationList(responseData);
            setDataTableEvent((event) => ({ ...event, totalRows: paginationData.total }));
        },
        onError: (error) => logger.log('Failed to fetch organization list', error),
        refetchOnWindowFocus: false,
    });
    const organizationListQueryRef = useRef(organizationListQuery);

    useEffect(() => {
        organizationListQueryRef.current.refetch();
    }, [dataTableEvent?.forceUpdate]);

    useEffect(() => {
        const searchPayload = searchColumns.map((column) => `&search[${column}]=${debouncedSearchValue}`).join('');
        setDataTableEvent((event) => ({
            ...event,
            search: debouncedSearchValue.trim().length > 0 ? searchPayload : '',
            forceUpdate: event.forceUpdate + 1,
        }));
    }, [debouncedSearchValue]);

    return (
        <>
            <MlxToast
                data-testid="toast"
                message={toastMessage.message}
                onClose={() => setToastMessage(defaultToastData)}
                show={toastMessage.show}
                variant={toastMessage.variant}
            />

            <AddOrganizationSidebar
                showSideBar={showSideBar}
                setShowSideBar={setShowSideBar}
                organization={editOrganization}
                setEditOrganization={setEditOrganization}
                organizationListQueryRef={organizationListQueryRef}
                setToastMessage={setToastMessage}
            />

            <div className="page-section">
                <span className="page-header">{translatedText.MANAGE_ORGANIZATION}</span>
                <div className="page-content">
                    <div className="content-header">
                        <span className="header-title">{searchBarVisible ? translatedText.ORG_LIST : ''}</span>
                        <div className="header-fields">
                            <MlxButton
                                className="ms-2"
                                removeLabelOnMobile={true}
                                size="small"
                                label={translatedText.INVITE_ORGANIZATION}
                                icon="ri-add-large-line"
                                iconPos="right"
                                onClick={() => setShowSideBar(true)}
                            />
                            <MlxSearch
                                className="ms-2 p-inputtext-sm"
                                removeLabelOnMobile={true}
                                placeholder={translatedText.SEARCH}
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                                searchBarVisible={setSearchBarVisible}
                                isLoading={organizationListQuery?.isFetching && !!debouncedSearchValue.trim().length}
                            />
                        </div>
                    </div>
                    <Divider className="content-divider" />
                    <div className="content-body">
                        <OrganizationList
                            dataTableEvent={dataTableEvent}
                            organizationList={organizationList}
                            organizationListQuery={organizationListQuery}
                            setDataTableEvent={setDataTableEvent}
                            setEditOrganization={setEditOrganization}
                            setShowSideBar={setShowSideBar}
                            setToastMessage={setToastMessage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default withSidebar(Organization);
